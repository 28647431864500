import React, { useState, useEffect } from "react";
import "./introSection.css";
import Society from "../../../assets/illustrations/hero.webp";
import ScalingButton from "../../../components/Buttons/ScalingButton/ScalingButton";
import ActiveButton from "../../../components/Buttons/ActiveButton/ActiveButton";
import RoomTypesModal from "../../AdPage/Dropdowns/SpaceDropdown/RoomTypesModal/RoomTypesModal";
import MunicipalityModal from "../../AdPage/Dropdowns/MunicipalityDropdown/MunicipalityModal/MunicipalityModal";

export default function IntroSection() {
  const [selectedRoomTypes, setSelectedRoomTypes] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [isModalRoomTypesOpen, setIsModalRoomTypesOpen] = useState(false);
  const [isMunicipalityModalOpen, setIsMunicipalityModalOpen] = useState(false);
  const [isAllaKommunerActive, setIsAllaKommunerActive] = useState(false);
  const [isAllaLokalerActive, setIsAllaLokalerActive] = useState(false);

  const saveToStorage = (key, value) =>
    sessionStorage.setItem(key, JSON.stringify(value));
  const loadFromStorage = (key, setter) => {
    const data = sessionStorage.getItem(key);
    if (data) setter(JSON.parse(data));
  };

  const updateData = (label, data, setter, saver) => {
    const updatedData = data.includes(label)
      ? data.filter((item) => item !== label)
      : [...data, label];
    setter(updatedData);
    saveToStorage(saver, updatedData);
  };

  useEffect(() => {
    loadFromStorage("selectedRoomTypes", setSelectedRoomTypes);
    loadFromStorage("selectedMunicipality", setSelectedLocations);
  }, []);


  const excludedRoomTypes = ["Möteslokal", "Eventlokal", "Kontorslokal"];
  const checkIfArrayIncludesExcludedRoomTypes = selectedRoomTypes.some((roomType) =>
    excludedRoomTypes.includes(roomType)
  );

  const excludedMunicipalities = ["Stockholm", "Göteborg", "Malmö"];
  const checkIfArrayIncludesExcludedMunicipalities = selectedLocations.some((municipality) => excludedMunicipalities.includes(municipality))


  useEffect(() => {
    setIsAllaLokalerActive(
      selectedRoomTypes.length > 0 && !checkIfArrayIncludesExcludedRoomTypes
    );
    setIsAllaKommunerActive(selectedLocations.length > 0 && !checkIfArrayIncludesExcludedMunicipalities);
  }, [
    selectedRoomTypes,
    selectedLocations,
    checkIfArrayIncludesExcludedRoomTypes,
    checkIfArrayIncludesExcludedMunicipalities
  ]);

  const handleButtonClick = (label, data, setter, saver) => {
    updateData(label, data, setter, saver);
  };

  const handleSearchButtonClick = () => {
    const params = new URLSearchParams();
    params.set("sida", 1);
    if (selectedRoomTypes.length > 0) {
      params.set("lokaltyp", selectedRoomTypes.join(","));
    }
    if (selectedLocations.length > 0) {
      params.set("kommun", selectedLocations.join(","));
    }
    window.location.href = `/lediga-lokaler?${params.toString()}`;
  };

  const handleOpenModal = (setter) => {
    setter(true);
  };

  return (
    <div className="intro-section">
      <div data-testid="home-intro-title" id="home-intro-title">
        <h1 data-testid="landingpage-header">
          Letar du lokal? <br />
          Låt oss hjälpa till!
          <br />
        </h1>
        <img
          src={Society}
          width="430"
          height="321"
          alt="Lite träd, några hus och en sjö"
        />
      </div>
      <div id="home-intro-filter-buttons">
        <p>Typ av lokal?</p>
        <div className="home-intro-btn-group">
          <div className="button-spacing">
            {[
              "Kontorslokal",
              "Eventlokal",
              "Möteslokal",
              "Visa andra lokaler",
            ].map((label, index) => (
              <ActiveButton
                key={index}
                label={label}
                size="md"
                isActive={
                  label === "Visa andra lokaler"
                    ? isAllaLokalerActive
                    : selectedRoomTypes.includes(label)
                }
                onClick={() => {
                  if (label === "Visa andra lokaler") {
                    handleOpenModal(setIsModalRoomTypesOpen);
                  } else {
                    handleButtonClick(
                      label,
                      selectedRoomTypes,
                      setSelectedRoomTypes,
                      "selectedRoomTypes"
                    );
                  }
                }}
              />
            ))}
            {isModalRoomTypesOpen && (
              <RoomTypesModal
                isOpen={isModalRoomTypesOpen}
                onRequestClose={() => setIsModalRoomTypesOpen(false)}
                selectedRoomTypes={selectedRoomTypes}
                setSelectedRoomTypes={setSelectedRoomTypes}
              />
            )}
          </div>
        </div>
        <p>Var söker ni?</p>
        <div className="button-spacing">
          {["Stockholm", "Göteborg", "Malmö", "Visa andra kommuner"].map(
            (label, index) => (
              <ActiveButton
                key={index}
                label={label}
                size="md"
                isActive={
                  label === "Visa andra kommuner"
                    ? isAllaKommunerActive
                    : selectedLocations.includes(label)
                }
                onClick={() => {
                  if (label === "Visa andra kommuner") {
                    handleOpenModal(setIsMunicipalityModalOpen);
                  } else {
                    handleButtonClick(
                      label,
                      selectedLocations,
                      setSelectedLocations,
                      "selectedMunicipality"
                    );
                  }
                }}
              />
            )
          )}
          {isMunicipalityModalOpen && (
            <MunicipalityModal
              isOpen={isMunicipalityModalOpen}
              onRequestClose={() => setIsMunicipalityModalOpen(false)}
              selectedMunicipality={selectedLocations}
              setSelectedMunicipality={setSelectedLocations}
            />
          )}
        </div>
        <div className="button-spacing-scaling">
          <ScalingButton
            label="Sök"
            color="white"
            size="md"
            onClick={handleSearchButtonClick}
          />
        </div>
      </div>
    </div>
  );
}
