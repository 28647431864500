import React, { useState } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { logAxiosError } from "../../../../services/api/error";
import { backend } from "../../../../backend";
import { ConfirmationModalComponent, ConfirmationModalBackdrop } from "./styles";
import TextButton from '../../../../components/Buttons/TextButton/TextButton';

function ConfirmationModal({ setShowConfirmationModal, userEmail, userObject }) {
  const [isResent, setIsResent] = useState(false);

  const resendValidation = async (email, user) => {
    try {
      const response = await axios.post(
        backend("/api/users/resend/activation/email"),
        {
          email: email.toLowerCase(),
          user,
        }
      );
      return response.data;
    } catch (error) {
      logAxiosError(error);
      throw error;
    }
  };

  const handleResendValidation = async () => {
    try {
      await resendValidation(userEmail, userObject);
      setIsResent(true);
    } catch (error) {
      console.error('Error resending validation email:', error);
    }
  };

  return (
    <>
      <ConfirmationModalComponent>
        <h2>Aktivera ditt konto</h2>
        <p>
          Ett aktiveringsmejl har skickats till din e-postadress.
          <br />
          Kolla din skräpkorg om du inte hittar mejlet.
        </p>
        <Link className='confirmation-button-link' to="/">Okej!</Link>
        <button onClick={() => setShowConfirmationModal(false)}>
          <img src="/images/icons/close.svg" alt="close-icon-button" />
        </button>
        <br />
        <br />
        <p>
          Har du inte fått något mail?
          &nbsp;
          <br />
          <TextButton
            size='xxs'
            color='green'
            label={isResent ? 'Nu är ett nytt mail på väg!' : 'Klicka här!'}
            onClick={isResent ? undefined : handleResendValidation}
          />
        </p>
      </ConfirmationModalComponent>
      <ConfirmationModalBackdrop
        onClick={() => setShowConfirmationModal(false)}
      />
    </>
  );
}

export default ConfirmationModal;
