import React from "react";
import "./videoSection.css";
import ActiveLinkButton from "../../../components/Buttons/ActiveLinkButton/ActiveLinkButton";
import vakansa from "../../../assets/videos/vakansa-small.mp4";

export default function VideoSection() {

  return (
    <div className={`home-video-section`}>
      <div className="home-about-video-inner">
        <div id="home-about-text">
          <h2>Lokaler står helt eller delvis tomma helt i onödan</h2>
          <div className="home-about-text-inner">
            <p>
              Vakansa startades med den enkla övertygelsen att det redan finns
              tillräckligt många byggnader och lokaler för alla samhällets
              aktörer.
            </p>
            <br />
            <p>
              Vi vet hur svårt det är att hitta billiga, passande och flexibla
              lokaler. Samtidigt vet vi att lokaler står helt eller delvis tomma
              överallt i våra städer.
            </p>
            <br />
            <p>
              Så behöver det inte vara! Vi på Vakansa ser till att lokaler
              används, istället för att stå tomma!
            </p>
          </div>
          <ActiveLinkButton
            label="Läs mer om Vakansa"
            size="md"
            href={"/om-oss"}
          />
        </div>
        <div className="home-about-video">
          <div className="video-container">
            <div className="video-card-container">
              <video controls width="100%" height="auto">
                <source src={vakansa} type="video/mp4" />
                your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
