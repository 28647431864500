import React from "react";
import ThankYouComponent from "./styles";
import { useNavigate } from "react-router-dom";
import RoundedLinkButton from "../../components/Buttons/RoundedLinkButton/RoundedLinkButton";
import SEOTags from "../../utils/SEOTags";

const Interested = () => {
  const navigate = useNavigate();

  return (
    <ThankYouComponent>
      <SEOTags
        title={"Tack! - Vakansa"}
        canonicalUrl={"/intresseanmalan"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <h2>Tack!</h2>
      <p>
        En bekräftelse på din intresseanmälan har nu trillat ner in i din
        digitala brevlåda. Fortsätt konversationen i mailet med hyresvärden.
      </p>
      <br />
      <br />

      <div className="thank-you-modal-btn-row">
        <button className="thank-you-modal-btn" onClick={() => navigate(-1)}>
          Gå tillbaka till tidigare annons
        </button>
      </div>
      <div className="thank-you-request">
        <p>Vill du ha flera kostnadsfria förslag på flera liknande lokaler?</p>
        <RoundedLinkButton
          label={"Skicka in din lokalförfrågan"}
          color={"green"}
          size={"lg"}
          href={"/forfragan"}
        />
      </div>
    </ThankYouComponent>
  );
};

export default Interested;
