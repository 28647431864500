import styled from "styled-components";

export const ConfirmationModalComponent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--z-index-modal);

  width: 69rem;
  padding: 5rem 0;
  background-color: var(--white);
  border-radius: 28px;
  text-align: center;

  h2 {
    font-size: 3.6rem;
    font-weight: 700;
    font-family: var(--montserrat);
    margin-bottom: 1.2rem;
  }

  p {
    font-size: var(--font-size-xx-small);
      
    line-height: 25px;
    padding-bottom: 4rem;
  }

  .confirmation-button-link {
    padding: 1.75rem 5rem;
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--white);
    text-decoration: none;
    background-color: var(--green);
    border-radius: 29px;
  }

  button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    height: 8rem;
    width: 8rem;
    background: none;
    border: none;
  }

   @media only screen and (max-width:750px) {
width: 500px;
  }

   @media only screen and (max-width:550px) {
width: 450px;
padding: 5rem 1rem 1rem 1rem;
  }

  @media only screen and (max-width:460px) {
width: 390px;
  }

  @media only screen and (max-width:400px) {
width: 370px;
padding: 5rem 0.5rem 0.5rem 0.5rem;
  }
`;

export const ConfirmationModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: var(--z-index-backdrop);

  background-color: var(--gray-black);
  opacity: 0.5;
`;