import React, { useRef } from 'react';
import "../ArticleSection/articleSection.css"
import ArticleCard from '../ArticleCard/ArticleCard';
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import CarouselCard from '../CarouselCard/CarouselCard';
import hub1 from "../../../assets/articles/hub-1.jpg"
import hub2 from "../../../assets/articles/hub-2.jpg"
import hub3 from "../../../assets/articles/hub-3.jpg"
import hub4 from "../../../assets/articles/hub-4.jpg"
import hub5 from "../../../assets/articles/hub-5.jpg"
import hub6 from "../../../assets/articles/hub-6.jpg"

export default function ArticleSection(props) {
  const isMobile = window.innerWidth < 980;
  const sliderRef = useRef(null)

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    responsive: [
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="article-section">
      <h2 className="article-header">
        Artiklar om oss
      </h2>
      {isMobile === true ? (
        <div className="article-mobile-slider">
          <Slider ref={sliderRef} {...settings}>
            <CarouselCard
              link={
                "https://www.svt.se/nyheter/lokalt/stockholm/jobbhubb-ska-underlatta-vardagspusslet-for-tullingebor"
              }
              img={hub1}
              title={"SVT Nyheter"}
              quote={"Jobb ska göra Tullingebors livspussel lättare"}
            />
            <CarouselCard
              link={
                "https://www.dn.se/sthlm/flexibelt-kvarterskontor-ska-ta-vara-pa-positiva-pandemieffekter/"
              }
              img={hub2}
              title={"Dagens Nyheter"}
              quote={
                "Flexibel kvarterskontor ska ta vara på positiva pandemieffekter"
              }
            />
            <CarouselCard
              link={
                "https://www.wsp.com/sv-se/nyheter/2020/wsp-och-vakansa-i-samarbete-om-framtidens-lokaler"
              }
              img={hub3}
              title={"WSP"}
              quote={"WSP och Vakansa i samarbete om framtidens lokaler"}
            />
            <CarouselCard
              link={
                "https://www.ri.se/sv/vad-vi-gor/cirkular-omstallning/cirkulara-kartan/vakansa"
              }
              img={hub4}
              title={"RISE"}
              quote={
                "Vakansa förvaltar tiden i byggnader och lokaler så att de används resurseffektivt"
              }
            />
            <CarouselCard
              link={
                "https://www.dn.se/sthlm/flexibelt-kvarterskontor-ska-ta-vara-pa-positiva-pandemieffekter/"
              }
              img={hub5}
              title={"Dagens Nyheter"}
              quote={
                "Flexibel kvarterskontor ska ta vara på positiva pandemieffekter"
              }
            />
            <CarouselCard
              link={
                "https://www.wsp.com/sv-se/nyheter/2020/wsp-och-vakansa-i-samarbete-om-framtidens-lokaler"
              }
              img={hub6}
              title={"WSP"}
              quote={"WSP och Vakansa i samarbete om framtidens lokaler"}
            />
          </Slider>
          <div className="article-arrows">
            <svg
              fill="currentColor"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              className="article-arrow article-arrow-prev"
              onClick={() => sliderRef.current?.slickPrev()}
            >
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 010 .708L5.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z"
              />
            </svg>
            <svg
              fill="currentColor"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              className="article-arrow article-arrow-next"
              onClick={() => sliderRef.current?.slickNext()}
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z"
              />
            </svg>
          </div>
        </div>
      ) : (
        <>
          {isMobile === false ? (
            <div className="articles">
              <ArticleCard
                link={
                  "https://www.svt.se/nyheter/lokalt/stockholm/jobbhubb-ska-underlatta-vardagspusslet-for-tullingebor"
                }
                img={hub1}
                title={"SVT Nyheter"}
                quote={"Jobb ska göra Tullingebors livspussel lättare"}
              />
              <ArticleCard
                link={
                  "https://www.dn.se/sthlm/flexibelt-kvarterskontor-ska-ta-vara-pa-positiva-pandemieffekter/"
                }
                img={hub2}
                title={"Dagens Nyheter"}
                quote={
                  "Flexibel kvarterskontor ska ta vara på positiva pandemieffekter"
                }
              />
              <ArticleCard
                link={
                  "https://www.wsp.com/sv-se/nyheter/2020/wsp-och-vakansa-i-samarbete-om-framtidens-lokaler"
                }
                img={hub3}
                title={"WSP"}
                quote={"WSP och Vakansa i samarbete om framtidens lokaler"}
              />
              <ArticleCard
                link={
                  "https://www.ri.se/sv/vad-vi-gor/cirkular-omstallning/cirkulara-kartan/vakansa"
                }
                img={hub4}
                title={"RISE"}
                quote={
                  "Vakansa förvaltar tiden i byggnader och lokaler så att de används resurseffektivt"
                }
              />
              <ArticleCard
                link={
                  "https://www.aktuellhallbarhet.se/alla-nyheter/karriar/fran-hallbarhetschef-till-start-up-entreprenor/"
                }
                img={hub5}
                title={"Aktuell Hållbarhet"}
                quote={"Från hållbarhetschef till start up-entreprenör"}
              />
              <ArticleCard
                link={
                  "https://unt.se/bli-prenumerant/artikel/kr2zonvr/unt-1m1kr_s_23"
                }
                img={hub6}
                title={"UNT"}
                quote={"Uppsalabo utmanar fastighetsbranschen med ny plattform"}
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
