import React, { useState } from "react";
import "./footer.css";
import TextButton from "../../Buttons/TextButton/TextButton";
import Logo from "../../../assets/logo/green-extra-small.png";
import CookieBanner from "../../CookieBanner";

export default function Footer(props) {
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const toggleCookieBanner = () => {
    setShowCookieBanner(!showCookieBanner);
  };

  return (
    <footer className="footer">
      <div className="footer-inner">
        <div className="footer-column">
          <span>OM VAKANSA</span>
          <ul>
            <li>
              <TextButton
                label="Om oss"
                color="white"
                size="xxs"
                href="/om-oss"
              />
            </li>
            <li>
              <TextButton
                label="Bli partner"
                color="white"
                size="xxs"
                href="/kontakta-oss"
              />
            </li>
            <li>
              <TextButton
                label="Kontorshotell"
                color="white"
                size="xxs"
                href="/kontorshotell"
              />
            </li>
            <li>
              <TextButton
                label="Populära lokaltyper"
                color="white"
                size="xxs"
                href="/populara-lokaltyper"
              />
            </li>
            <li>
              <TextButton
                label="Andrahandsuthyrning"
                color="white"
                size="xxs"
                href="/andrahandsuthyrning"
              />
            </li>
            <li>
              <TextButton
                label="Hållbarhet"
                color="white"
                size="xxs"
                href="/hallbarhet"
              />
            </li>
            <li>
              <TextButton
                label="Hyr en lokal"
                color="white"
                size="xxs"
                href="/hyr-en-lokal"
              />
            </li>
          </ul>
        </div>
        <div className="footer-column-support">
          <span>SUPPORT</span>
          <ul>
            <li>
              <TextButton
                label="Frågor och Svar"
                color="white"
                size="xxs"
                href="/fragor-och-svar"
              />
            </li>
            <li>
              <TextButton
                label="Kontakta oss"
                color="white"
                size="xxs"
                href="/kontakta-oss"
              />
            </li>
            <li>
              <TextButton
                label="info@vakansa.se"
                href="mailto:info@vakansa.se"
                rel="noopener noreferrer"
                color="white"
                size="xxs"
              />
            </li>
            <li>
              <TextButton
                label="Tel: 076-706 56 62"
                color="white"
                size="xxs"
                href="tel:+46767065662"
              />
            </li>
            <li>
              <TextButton
                label="Lokalförfrågan"
                color="white"
                size="xxs"
                href="/forfragan"
              />
            </li>
            <li>
              <TextButton
                label="Cookieinställningar"
                color="white"
                size="xxs"
                onClick={toggleCookieBanner}
              />
            </li>
            <li>
              <TextButton
                label="Användarvillkor"
                color="white"
                size="xxs"
                href="/anvandarvillkor"
              />
            </li>
          </ul>
        </div>
        <div className="footer-column-follow">
          <span>FÖLJ OSS</span>
          <ul>
            <li>
              <a
                className="footer-ext-links"
                href="https://www.facebook.com/Vakansa/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  fill="none"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  {...props}
                >
                  <path
                    fill="#588f61"
                    d="M9.198 21.5h4v-8.01h3.604l.396-3.98h-4V7.5a1 1 0 011-1h3v-4h-3a5 5 0 00-5 5v2.01h-2l-.396 3.98h2.396v8.01z"
                  />
                </svg>
                Facebook &nbsp;
              </a>
            </li>
            <li>
              <a
                className="footer-ext-links"
                href="https://www.linkedin.com/company/vakansa/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  fill="#588f61"
                  height="1em"
                  width="1em"
                  {...props}
                >
                  <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1168.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z" />
                </svg>
                LinkedIn &nbsp; &nbsp; &nbsp;
              </a>
            </li>
            <li>
              <a
                className="footer-ext-links"
                href="https://www.instagram.com/vakansa.se/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  fill="#588f61"
                  height="1em"
                  width="1em"
                  {...props}
                >
                  <path d="M512 378.7c-73.4 0-133.3 59.9-133.3 133.3S438.6 645.3 512 645.3 645.3 585.4 645.3 512 585.4 378.7 512 378.7zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zM512 717.1c-113.5 0-205.1-91.6-205.1-205.1S398.5 306.9 512 306.9 717.1 398.5 717.1 512 625.5 717.1 512 717.1zm213.5-370.7c-26.5 0-47.9-21.4-47.9-47.9s21.4-47.9 47.9-47.9 47.9 21.4 47.9 47.9a47.84 47.84 0 01-47.9 47.9z" />
                </svg>
                Instagram &nbsp;
              </a>
            </li>
          </ul>
        </div>
      </div>
      {showCookieBanner && <CookieBanner visible={showCookieBanner} />}
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Vakansa AB</p>
        <img
          loading="lazy"
          className="footer-logo"
          src={Logo}
          alt="vakansa logo"
        />
        <p>Org.nr 559214-1161</p>
      </div>
    </footer>
  );
}
