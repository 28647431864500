import ReactGA from "react-ga";
import Cookies from "js-cookie";

/**
 * 
 * @param {Object} args - category, action and label for a event
 * @param {string} args.category - The swedish name of the page this event is on
 * @param {string} args.action - A short description of the event
 * @param {string} args.label - The label or a description of the of the element that triggers the event
 */
const trackEvent = (args) => {
  if (Cookies.get("consent") === "true" && !window.location.href.includes("localhost")) {
    ReactGA.event(args)
  }
}

export default trackEvent;