import React from "react";
import "./noAdResults.css";
import AdCard from "../AdCard/AdCard";
import RoundedLinkButton from "../../../components/Buttons/RoundedLinkButton/RoundedLinkButton";

export default function NoResults({ id }) {
  const sortedData = id.sort((a, b) => new Date(b.date) - new Date(a.date));

  const latestAds = sortedData.slice(0, 3);
  

  return (
    <div className="no-results-ad-section">
      <div className="no-card-outer-link">
        <article className={`no-search-result-card`}>
          <div className="no-card-image">
            <div className="no-card-image-text">
              <p>Kan vi inte erbjuda dig din drömlokal just nu?</p>
              <p>Ingen fara!</p>
            </div>
          </div>
          <div className="search-no-card">
            <p>
              Skicka in en lokalförfrågan så letar vi upp en lokal åt er -
              kostnadsfritt!
            </p>
            <RoundedLinkButton
              size="lg"
              label="Skicka in lokalförfrågan"
              href={"/forfragan"}
              />
          </div>
        </article>
      </div>
      <h2>Det kanske finns något annat som passar dig</h2>
      {latestAds.length > 0 && (
        <div className="no-ads-container">
          {latestAds.map((ad) => (
            <div className="no-ads-generated-ads" key={ad._id}>
              <AdCard id={ad} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
