import React from "react";
import "./rentVision.css";
import Sustainable from "../../../assets/icons/sustainable.png";
import Buildings from "../../../assets/images/old-buildings.webp";
import Desk from "../../../assets/images/work-desk.webp";
import RoundedLinkButton from "../../../components/Buttons/RoundedLinkButton/RoundedLinkButton";

export default function RentVision() {
  const isMobile = window.innerWidth < 580;

  return (
    <>
      {isMobile === true ? (
        <div className="rent-vision">
          <div className="rent-out-mobile">
            <div className="rent-out-text-mobile">
              <h2>Hyr ut lokal</h2>
              <p>
                Har du en lokal som du vill hyra ut? Med Vakansa kan du enkelt
                och smidigt marknadsföra din lokal och nå rätt målgrupp.
              </p>
            </div>
            <div className="rent-out-bottom-text-mobile">
              <p>
                Registrera din lokal på vår plattform idag genom att skapa ett
                kostnadsfritt konto och få tillgång till tusentals potentiella
                hyresgäster.
              </p>
              <br />
              <RoundedLinkButton
                label={"Läs mer om att bli hyresvärd"}
                color={"whiteExtra"}
                size={"lg"}
                href={"/hyr-ut-lokal"}
              />
            </div>
            <img
              className="rent-out-image"
              src={Desk}
              alt="man standing by a desk"
              loading="lazy"
            />
          </div>

          <div className="vision-mobile">
            <div className="vision-image-container">
              <img
                src={Buildings}
                className="rent-vision-image"
                alt="old city scape"
                loading="lazy"
              />
              <div className="vision-circle">
                <img
                  src={Sustainable}
                  alt="sustainable house icon"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="vision-text-mobile">
              <h3>Vår vision</h3>
              <p>
                Vakansa startades med den enkla övertygelsen att det redan finns
                tillräckligt många byggnader och lokaler för alla samhällets
                aktörer.
              </p>
            </div>
            <div className="vision-text-bttom-mobile">
              <p>
                Vi ser hur byggnader och lokaler står helt eller delvis tomma
                överallt i våra städer. Vakansas vision är att främja en hållbar
                stadsutveckling där vi möter människors lokalbehov, utan att det
                behöver byggas nytt.
              </p>
              <br />
              <RoundedLinkButton
                label={"Läs mer om Vakansa"}
                color={"whiteExtra"}
                size={"lg"}
                href={"/om-oss"}
              />
            </div>
          </div>
        </div>
      ) : isMobile === false ? (
        <div className="rent-vision">
          <div className="rent-out">
            <div className="rent-out-text">
              <h2>Hyr ut lokal</h2>
              <p>
                Har du en lokal som du vill hyra ut? Med Vakansa kan du enkelt
                och smidigt marknadsföra din lokal och nå rätt målgrupp.
              </p>
              <p>
                Registrera din lokal på vår plattform idag genom att skapa ett
                kostnadsfritt konto och få tillgång till tusentals potentiella
                hyresgäster.
              </p>
              <br />
              <RoundedLinkButton
                label={"Läs mer om att bli hyresvärd"}
                color={"whiteExtra"}
                size={"lg"}
                href={"/hyr-ut-lokal"}
              />
            </div>
            <img
              className="rent-out-image"
              src={Desk}
              alt="man standing by a desk"
            />
          </div>

          <div className="vision">
            <div className="vision-image-container">
              <img
                src={Buildings}
                className="rent-vision-image"
                alt="old city scape"
              />
              <div className="vision-circle">
                <img src={Sustainable} alt="sustainable house icon" />
              </div>
            </div>
            <div className="vision-text">
              <h2>Vår vision</h2>
              <p>
                Vakansa startades med den enkla övertygelsen att det redan finns
                tillräckligt många byggnader och lokaler för alla samhällets
                aktörer. Vi ser hur byggnader och lokaler står helt eller delvis
                tomma överallt i våra städer.
              </p>
              <p>
                Vakansas vision är att främja en hållbar stadsutveckling där vi
                möter människors lokalbehov, utan att det behöver byggas nytt.
              </p>
              <br />
              <RoundedLinkButton
                label={"Läs mer om Vakansa"}
                color={"whiteExtra"}
                size={"lg"}
                href={"/om-oss"}
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
