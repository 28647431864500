import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import Cookie from "js-cookie"

/**
 * Initialize Google analytics and start tracking what page the user is on.
 * @param {object} location - A react-router-dom location object from the useLocation() hook
 */
export const startGoogleAnalytics = (location) => {
  ReactGA.initialize("G-17KJT2Q43Y");
  ReactGA.pageview(location.pathname + location.search);
}

/**
 * Initialize Google Analytics globally with react-ga.
 * This custom hook ignores localhost, only works in prod
 */
const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (Cookie.get("consent") === "true" && !window.location.href.includes("localhost")) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location]);
};

export default useGoogleAnalytics;
