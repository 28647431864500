import React, { useRef } from "react";
import "./steps.css";
import HousePin from "../../../assets/icons/house-location-pin.png";
import Deal from "../../../assets/icons/deal.png";
import Contract from "../../../assets/icons/contract.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Steps(props) {
  const isMobile = window.innerWidth < 580;

  const sliderRef = useRef(null);

  const settings = {
    speed: 500,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: false,
  };

  return (
    <div className="steps">
      <div className="steps-top">
        <h2>Hur fungerar Vakansa?</h2>
        <p>
          Vi på Vakansa vet hur utmanande det kan vara att hitta prisvärda,
          passande och flexibla lokaler för sin verksamhet. Samtidigt är det
          uppenbart att byggnader och lokaler står helt eller delvis tomma runt
          om i våra städer. Innan pandemin visade statistiken att kontor
          användes så lite som 10% av tiden och skolor 16% av tiden. Enorma
          mängder pengar som går till uppvärmd luft...
        </p>
        <br />
        <p>
        Så behöver det inte vara!
        </p>
        <br/>
        <p>
          Vi på Vakansa strävar vi efter att utnyttja
          varje lokal till sin fulla potential. Oavsett om ni letar efter en
          flexibel kontorslokal, en inspirerande ateljé, en idrottshall för
          barnkalas eller ett behandlingsrum så har vi något för er.
        </p>
        <br />
        <p>
          Vi har ett stort utbud av dolda lokaler som hyrs ut i andrahand och
          som ni annars inte hade upptäckt!
        </p>
      </div>
      {isMobile === true ? (
        <div className="steps-container-carousel-outer">
          <div
            className="steps-container-carousel"
            style={{ margin: "0 auto" }}
          >
            <Slider ref={sliderRef} {...settings}>
              <div className="steps-outer-carousel">
                <div className="steps-circle">
                  <img src={HousePin} alt="house with a pin" />
                </div>
                <span>Steg 1</span>
                <b className="description-heading" >Hitta lokal</b>
                <p>
                  Utforska lediga lokaler på Vakansas plattform och skicka in
                  din intresseanmälan direkt till hyresvärden
                </p>
              </div>

              <div className="steps-outer-carousel">
                <div className="steps-circle">
                  <img src={Deal} alt="handshake" />
                </div>
                <span>Steg 2</span>
                <b>Boka visning</b>
                <p>
                  När du och hyresvärden har kontakt kan ni boka tid för en
                  visning, där hyresvärden arrangerar möjligheten för dig att se
                  lokalen och ställa eventuella frågor
                </p>
              </div>

              <div className="steps-outer-carousel">
                <div className="steps-circle">
                  <img src={Contract} alt="A signed contract" />
                </div>
                <span>Steg 3</span>
                <b>Skriv avtal</b>
                <p>
                  Om allt ser bra ut och hyresvärden godkänner din ansökan,
                  skrivs ett kontrakt mellan dig och hyresvärden. Därefter får
                  du tillgång till lokalen!
                </p>
              </div>
            </Slider>
          </div>
          <div className="steps-arrows">
            <svg
              fill="currentColor"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              {...props}
              className="steps-arrow steps-arrow-prev"
              onClick={() => sliderRef?.current?.slickPrev()}
            >
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 010 .708L5.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z"
              />
            </svg>
            <svg
              fill="currentColor"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              {...props}
              className="steps-arrow steps-arrow-next"
              onClick={() => sliderRef?.current?.slickNext()}
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z"
              />
            </svg>
          </div>
        </div>
      ) : (
        <>
          {isMobile === false ? (
            <div className="steps-container">
              <div className="steps-outer">
                <div className="steps-circle">
                  <img src={HousePin} alt="house with a pin" />
                </div>
                <span>Steg 1</span>
                <b className="steps-heading">Hitta och ansök lokal</b>
                <p className="steps-text">
                  Utforska lediga lokaler på Vakansas plattform och skicka in
                  din intresseanmälan direkt till hyresvärden
                </p>
              </div>

              <div className="steps-outer">
                <div className="steps-circle">
                  <img src={Deal} alt="handshake" />
                </div>
                <span>Steg 2</span>
                <b className="steps-heading">Boka visning</b>
                <p className="steps-text">
                  När du och hyresvärden har kontakt kan ni boka tid för en
                  visning, där hyresvärden arrangerar möjligheten för dig att se
                  lokalen och ställa eventuella frågor
                </p>
              </div>

              <div className="steps-outer">
                <div className="steps-circle">
                  <img src={Contract} alt="A signed contract" />
                </div>
                <span>Steg 3</span>
                <b className="steps-heading">Skriv avtal</b>
                <p className="steps-text">
                  Om allt ser bra ut och hyresvärden godkänner din ansökan,
                  skrivs ett kontrakt mellan dig och hyresvärden. Därefter får
                  du tillgång till lokalen!
                </p>
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
