import React, { useEffect } from "react";
import Team from "../../assets/illustrations/team2.png";
import "../CoworkingPage/coworkingPage.css";
import ArticleSection from "./ArticleSection/ArticleSection";
import ActiveLinkButton from "../../components/Buttons/ActiveLinkButton/ActiveLinkButton";
import SEOTags from "../../utils/SEOTags";

function Coworking() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <>
      <SEOTags
        title={"Kontorshotell och coworking - Vakansa"}
        description={"Upptäck flexibla kontorslösningar med Vakansa"}
        canonicalUrl={"/kontorshotell"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <main className={`coworking-page`}>
        <div className="intro-content">
          <div className="intro-text">
            <h1>Kontorshotell och coworking</h1>
            <h2>Upptäck flexibla kontorslösningar med Vakansa</h2>
            <p>
              Välkommen till Vakansa – din portal till en värld av flexibla och
              moderna kontorslösningar. Hos oss hittar du de bästa alternativen
              för kontorshotell och coworking-spaces som möter dagens dynamiska
              arbetsliv. Oavsett om du är en startup, frilansare eller ett
              etablerat företag, erbjuder Vakansa skräddarsydda arbetsplatser
              som inspirerar till kreativitet och produktivitet.
            </p>
            <br />
            <b>Kontorshotell – Skräddarsydd Flexibilitet</b>
            <p>
              På Vakansa.se har vi kontorshotell med den perfekta lösningen för
              företag som söker maximal flexibilitet utan de långa
              bindningstider och fasta kostnader som traditionella
              kontorslokaler medför. Välj en arbetsplats i en professionell
              miljö med tillgång till alla nödvändiga faciliteter som mötesrum,
              kök och teknisk support. Med Vakansa kan du hitta anpassade
              kontorslösningar efter ditt företags unika behov, oavsett om det
              gäller enstaka skrivbord eller ett helt kontorslandskap.
            </p>
            <br />
            <b>Coworking – Samarbete och Nätverk</b>
            <p>
              Coworking genom Vakansas alla uthyrare ger dig inte bara en
              arbetsplats, utan en chans att ingå i ett levande community av
              likasinnade professionella. Hitta en ny plats där du kan utöka
              ditt nätverk, dela idéer och dra nytta av en kreativ och
              stimulerande arbetsmiljö. Många av de utannonserade
              coworking-utrymmen finns i flera attraktiva lägen, varje med sin
              unika karaktär och gemenskap.
            </p>
            <p>
              Oavsett om du behöver ett tyst skrivbord för dagen eller en mer
              permanent lösning, är Vakansa här för att hjälpa dig att hitta den
              perfekta platsen. Utforska vårt utbud av kontorshotell och
              coworking-spaces på <a href="http://Vakansa.se">Vakansa.se</a>.
            </p>

            <ActiveLinkButton
              size="md"
              href={
                "/lediga-lokaler?sida=1&lokaltyp=Kontorshotell%2FCo-working"
              }
              label="Hitta kontorshotell/coworking"
            />
          </div>
          <img
            className="intro-img"
            src={Team}
            alt="Företagets logotyp med illustrerade människor som står tillsammans"
          />
        </div>
        <ArticleSection />
      </main>
    </>
  );
}

export default Coworking;
