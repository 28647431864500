import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { startGoogleAnalytics } from "../customHooks/useGoogleAnalytics";
import { startHotjar } from "../customHooks/useHotjar";
import Cookie from "js-cookie";
import styled from "styled-components";
import trackEvent from "../functions/trackEvent";

function CookieBanner({ visible: visibleModal }) {
  const [visible, setVisible] = useState(visibleModal);
  const location = useLocation();

  useEffect(() => {
    if (Cookie.get("consent") === undefined) {
      setVisible(true);
    }
  }, []);

  const accept = () => {
    // Set consent cookie
    Cookie.set("consent", true, {
      sameSite: "strict",
      expires: 365,
    });

    startHotjar();

    // Start tracking with GA
    startGoogleAnalytics(location);
    trackEvent({
      category: "Cookies",
      action: "Acceptera cookies",
      label: "Okej",
    });

    // Hide banner
    setVisible(false);
  };

  const reject = () => {
    Cookie.set("consent", false, {
      sameSite: "strict",
      expires: 365,
    });
    setVisible(false);
  };

  const openTermsOfService = () => {
    window.open("/anvandarvillkor#cookies", "_blank");
  };

  return visible ? (
    <>
      <CookieBannerComponent data-testid="cookie-banner">
        <img src="/images/icons/cookie.svg" width="35" height="35" alt="icon-of-a-halfeaten-cookie" />
        <p>
          Vi använder tredjepartcookies för att förbättra din upplevelse på vår
          hemsida. Genom att klicka på "Okej" godkänner du användandet av
          cookies.
          <span onClick={openTermsOfService}>Läs mer</span>
        </p>
        <button className="cookie-reject" onClick={() => reject()}>
          Nej tack
        </button>
        <button
          data-testid="cookie-accept-btn"
          className="cookie-accept"
          onClick={() => accept()}
        >
          Okej
        </button>
      </CookieBannerComponent>
      <CookieBannerBackdrop />
    </>
  ) : null;
}

const CookieBannerComponent = styled.div`
  position: fixed;
  left: 50%;
  bottom: 3.5rem;
  transform: translateX(-50%);

  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-auto-rows: none;
  align-items: center;
  min-height: 120px;
  width: 780px;
  padding: 2rem 3rem 3rem;
  box-shadow: var(--shadow-light);
  background-color: var(--white);
  border-radius: 6px;
  z-index: 1000;

  img {
    width: 100%;
    max-width: 45px;
    min-width: 35px;
  }

  p {
    grid-column: 2 / -4;
    grid-row: 1 / -1;
    font-size: 1.4rem;
    margin-left: 1.5rem;
    margin-bottom: 0;

    span {
      display: block;
      color: var(--green);
      text-decoration: underline;
      cursor: pointer;
    }
  }

  button {
    cursor: pointer;
  }

  .cookie-accept,
  .cookie-reject {
    align-self: end;
    white-space: nowrap;
    border: none;
  }

  .cookie-accept {
    grid-column: -2 / -1;
    max-width: 140px;

    font-size: 1.8rem;
    font-weight: 700;
    color: var(--white);

    padding: 1rem 2.6rem 1.2rem;
    background-color: var(--green);
    border-radius: 6px;
  }

  .cookie-reject {
    grid-column: -4 / -2;

    font-size: 1.6rem;
    color: var(--text-light);
    text-decoration: underline;

    padding-right: 2rem;
    padding-bottom: 1.2rem;
    background: none;
  }

  @media only screen and (max-width: 1680px) {
    p {
      font-size: 1.6rem;
      /* margin-left: 3rem; */
    }
  }

  @media only screen and (max-width: 980px) {
    width: 560px;
    bottom: 2.5rem;
  }

  @media only screen and (max-width: 720px) {
    grid-template-rows: 1fr 0.5fr;
    /* padding: 2.5rem 1.5rem; */
    padding: 2.5rem 3rem;
    width: 440px;

    img {
      margin-top: 1.5rem;
    }

    p {
      padding: 0 1.5rem;
      grid-column: 2 / -2;
    }

    .cookie-accept,
    .cookie-reject {
      /* align-self: center; */
    }

    .cookie-reject {
      grid-column: -2 / -1;
      grid-row: -2 / -1;
      padding: 1rem 0 1.2rem;
    }
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, minmax(10px, min-content));
    justify-items: center;
    max-width: 340px;
    width: 92%;
    padding: 3rem 2rem 2.4rem;

    img {
      grid-row: 1 / 2;
      width: 6rem;
      margin: 0;
    }

    p {
      grid-row: 2 / 3;
      width: 100%;
      font-size: 1.6rem;
      text-align: center;
      margin: 2rem 0 3rem;
      padding: 0;
    }

    .cookie-accept {
      grid-row: -3 / -2;
      width: 120px;
    }

    .cookie-reject {
      grid-row: -2 / -1;
      padding-top: 1.8rem;
    }

    button {
      padding: 1.4rem 5rem 1.65rem;
      margin: 0;
    }
  }

  @media only screen and (max-width: 400px) {
    bottom: 15%;
    z-index: var(--z-index-modal);
  }
`;

const CookieBannerBackdrop = styled.div`
  display: none;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: var(--z-index-backdrop);

  background-color: var(--gray-black);
  opacity: 0.5;

  @media only screen and (max-width: 400px) {
    display: block;
  }
`;

export default CookieBanner;
