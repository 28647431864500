import React from "react";
import "./seoLinks.css";

export default function SeoLinks() {
  return (
    <div className="links-for-seo">
      <p>Länkar</p>
      <ul>
        <li>
          <a href="/lediga-lokaler?sida=1&kommun=Stockholm">
            Lediga lokaler i Stockholm
          </a>
          <p>
            Utforska ett brett utbud av lediga lokaler i Stockholm, perfekt för
            företag av alla storlekar.
          </p>
        </li>
        <li>
          <a href="/lediga-lokaler?sida=1&kommun=Uppsala">
            Lediga lokaler i Uppsala
          </a>
          <p>
            Hitta lediga lokaler i Uppsala som passar dina affärsbehov och väx
            med din verksamhet.
          </p>
        </li>
        <li>
          <a href="/lediga-lokaler?sida=1&kommun=Göteborg">
            Lediga lokaler i Göteborg
          </a>
          <p>
            Upptäck de bästa lediga lokalerna i Göteborg för att stödja ditt
            företags tillväxt.
          </p>
        </li>
        <li>
          <a href="/lediga-lokaler?sida=1&kommun=Malmö">
            Lediga lokaler i Malmö
          </a>
          <p>
            Bläddra bland lediga lokaler i Malmö och hitta den perfekta platsen
            för din verksamhet.
          </p>
        </li>
      </ul>

      <ul>
        <li>
          <a href="/lediga-lokaler?sida=1&lokaltyp=Kontorshotell%2FCo-working%2CKontorslokal&kommun=Stockholm">
            Lediga kontor i Stockholm
          </a>
          <p>
            Letar du efter kontor i Stockholm? Se våra lediga kontorslokaler och
            coworking spaces.
          </p>
        </li>
        <li>
          <a href="/lediga-lokaler?sida=1&lokaltyp=Kontorshotell%2FCo-working%2CKontorslokal&kommun=Uppsala">
            Lediga kontor i Uppsala
          </a>
          <p>
            Hitta det perfekta kontoret i Uppsala för att främja ditt företags
            produktivitet.
          </p>
        </li>
        <li>
          <a href="/lediga-lokaler?sida=1&lokaltyp=Kontorshotell%2FCo-working%2CKontorslokal&kommun=Göteborg">
            Lediga kontor i Göteborg
          </a>
          <p>
            Utforska lediga kontorslokaler i Göteborg, från coworking spaces
            till privata kontor.
          </p>
        </li>
        <li>
          <a href="/lediga-lokaler?sida=1&lokaltyp=Kontorshotell%2FCo-working%2CKontorslokal&kommun=Malmö">
            Lediga kontor i Malmö
          </a>
          <p>
            Se våra lediga kontor i Malmö och hitta en arbetsplats som passar
            ditt team.
          </p>
        </li>
      </ul>

      <ul>
        <li>
          <a href="/lediga-lokaler?sida=1&lokaltyp=Möteslokal&kommun=Stockholm">
            Lediga möteslokaler i Stockholm
          </a>
          <p>
            Hitta möteslokaler i Stockholm för alla typer av möten och
            företagsevenemang.
          </p>
        </li>
        <li>
          <a href="/lediga-lokaler?sida=1&lokaltyp=Möteslokal&kommun=Uppsala">
            Lediga möteslokaler i Uppsala
          </a>
          <p>
            Sök efter lediga möteslokaler i Uppsala som är idealiska för
            affärsmöten och konferenser.
          </p>
        </li>
        <li>
          <a href="/lediga-lokaler?sida=1&lokaltyp=Möteslokal&kommun=Göteborg">
            Lediga möteslokaler i Göteborg
          </a>
          <p>
            Upptäck möteslokaler i Göteborg för både små och stora
            sammankomster.
          </p>
        </li>
        <li>
          <a href="/lediga-lokaler?sida=1&lokaltyp=Möteslokal&kommun=Malmö">
            Lediga möteslokaler i Malmö
          </a>
          <p>
            Hitta möteslokaler i Malmö som passar ditt nästa affärsmöte eller
            evenemang.
          </p>
        </li>
      </ul>

      <ul>
        <li>
          <a href="/lediga-lokaler?sida=1&lokaltyp=Eventlokal&kommun=Stockholm">
            Lediga eventlokaler i Stockholm
          </a>
          <p>
            Utforska eventlokaler i Stockholm för alla typer av evenemang, från
            fester till konferenser.
          </p>
        </li>
        <li>
          <a href="/lediga-lokaler?sida=1&lokaltyp=Eventlokal&kommun=Uppsala">
            Lediga eventlokaler i Uppsala
          </a>
          <p>
            Hitta den perfekta eventlokalen i Uppsala för ditt nästa stora
            evenemang.
          </p>
        </li>
        <li>
          <a href="/lediga-lokaler?sida=1&lokaltyp=Eventlokal&kommun=Göteborg">
            Lediga eventlokaler i Göteborg
          </a>
          <p>
            Se våra lediga eventlokaler i Göteborg och välj en plats som passar
            ditt evenemang.
          </p>
        </li>
        <li>
          <a href="/lediga-lokaler?sida=1&lokaltyp=Eventlokal&kommun=Malmö">
            Lediga eventlokaler i Malmö
          </a>
          <p>
            Upptäck eventlokaler i Malmö för att arrangera framgångsrika
            evenemang och fester.
          </p>
        </li>
      </ul>
    </div>
  );
}
