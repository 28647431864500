import styled from "styled-components";

export const TermsPage = styled.main`
  padding: var(--spacing-lg);

  h1 {
    font-size: var(--font-size-large);
    font-weight: 700;
    color: var(--green);
  }

  h3 {
    font-size: var(--font-size-large);
    font-weight: 700;
    color: var(--green);
  }

  h2 {
    font-size: var(--font-size-medium);
    font-weight: 900;
    margin-top: 3.5rem;
    padding-right: 2rem;
    margin-bottom: 2.8rem;
    /* white-space: nowrap; */
  }

  h3 {
    font-size: 3rem;
    font-weight: 700;
    margin-top: 2.5rem;
    padding-right: 2rem;
    margin-bottom: 2rem;
    /* white-space: nowrap; */
  }

  p {
    font-size: var(--font-size-x-small);
    padding-bottom: 2.5rem;
    line-height: 1.35;
  }

  a {
    color: var(--green);
  }

  .terms-main-text {
    width: 100%;

    p {
      margin-right: 5%;
    }
  }

  @media only screen and (max-width: 1860px) {
    padding: var(--spacing-xl) 10%;
  }

  @media only screen and (max-width: 1580px) {
    grid-template-columns: 1fr;
    grid-template-rows: min-content;

    .terms-main-text {
      width: 100%;

      p {
        margin: 0;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    padding: 1rem;

    h1 {
      font-size: var(--font-size-medium);
    }

    h3 {
      font-size: var(--font-size-small);
    }

    h2 {
      font-size: var(--font-size-small);
    }
  }
`;
