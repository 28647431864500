import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "./registerForm.css";
import RoundedButton from "../../../../components/Buttons/RoundedButton/RoundedButton";

function RegisterForm({ onFormSubmit, serverError }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleRepeatPasswordVisibility = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const password = watch("password", "");
  const email = watch("email", "");

  const onSubmit = (formData) => {
    onFormSubmit(formData);
  };

  return (
    <div className={`register-form`}>
      <div className={`register-form-container`}>
        <h1>Registrera ett konto för att skapa en annons</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="register-firstname">Förnamn*</label>
          {errors.first_name && (
            <span className="register-form-error-msg">
              Vänligen ange ett förnamn
            </span>
          )}
          <input
            className={`input-field`}
            data-testid="register-input-first-name"
            {...register("first_name", { required: true })}
            type="text"
            id="register-firstname"
          />

          <label htmlFor="register-lastname">Efternamn</label>
          <input
            className={`input-field`}
            {...register("last_name", { required: false })}
            type="text"
            id="register-lastname"
          />

          <label htmlFor="register-email">E-postadress*</label>
          {errors.email && (
            <span className="register-form-error-msg">
              Vänligen ange en giltig e-postadress
            </span>
          )}
          <input
            className={`input-field`}
            {...register("email", {
              required: true,
              pattern: {
                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: "Ogiltig e-postadress",
              },
            })}
            type="text"
            data-testid="register-input-email"
            id="register-email"
          />

          <div className="register-email-div">
            <label htmlFor="register-email">Upprepa E-postadress*</label>
            {errors.repeat_email && (
              <span className="register-form-error-msg">
                E-postadress matchar ej
              </span>
            )}
            <input
              className={`input-field`}
              {...register("repeat_email", {
                required: true,
                validate: (value) =>
                  value === email || "E-postadress matchar ej",
              })}
              type="text"
              id="register-repeat-password"
            />
          </div>

          <label htmlFor="register-phone">Telefonnummer</label>
          <input
            className={`input-field`}
            {...register("mobile", {
              required: false,
              pattern: {
                value: /^[0-9\s+-]+$/,
              },
            })}
            type="text"
            id="register-phone"
          />

          <label htmlFor="register-organisation">Organisationsnamn</label>

          <input
            className={`input-field`}
            {...register("organization", { required: false })}
            type="text"
            id="register-organisation"
          />

          <label htmlFor="register-organisation-number">
            Organisationsnummer*
          </label>
          {errors.organization_number && (
            <span className="register-form-error-msg">
              Vänligen ange ett giltigt organisationsnummer
            </span>
          )}
          <input
            className={`input-field`}
            {...register("organization_number", { required: true })}
            type="text"
            id="register-organisation-number"
          />

          <label htmlFor="register-password">Lösenord*</label>
          {errors.password && (
            <span className="register-form-error-msg">
              Lösenordet ska bestå av minst 8 tecken
            </span>
          )}
          <div className="register-password-div">
            <input
              data-testid="register-input-password"
              className={`input-field`}
              {...register("password", { required: true })}
              type={showPassword ? "text" : "password"}
              id="register-password"
            />
            <div className="show-password" onClick={togglePasswordVisibility}>
              <img
                src={`/images/icons/eye_${
                  showPassword ? "visible" : "hidden"
                }.svg`}
                alt={showPassword ? "visible" : "hidden"}
              />
            </div>
          </div>
          <label htmlFor="register-repeat-password">Upprepa lösenord*</label>
          {errors.repeat_password && (
            <span className="register-form-error-msg">
              Lösenorden matchar ej
            </span>
          )}

          <div className="register-password-div">
            <input
              className={`input-field`}
              {...register("repeat_password", {
                required: true,
                validate: (value) =>
                  value === password || "Lösenorden matchar ej",
              })}
              data-testid="register-input-repeat-password"
              type={showRepeatPassword ? "text" : "password"}
              id="register-repeat-password"
            />
            <div
              className="show-password"
              onClick={toggleRepeatPasswordVisibility}
            >
              <img
                src={`/images/icons/eye_${
                  showRepeatPassword ? "visible" : "hidden"
                }.svg`}
                alt={showRepeatPassword ? "visible" : "hidden"}
              />
            </div>
          </div>
          <p>Fält markerade med * är obligatoriska</p>
          {errors.terms && (
            <span className="register-form-error-msg">
              Du måste acceptera användarvillkoren
            </span>
          )}

          <div className="register-form-checkbox">
            <input
              data-testid="register-checkbox"
              type="checkbox"
              {...register("terms", { required: true })}
            />
            <div>
              <p className="register-form-terms-text">
                Jag har läst och accepterar&nbsp;
                <a
                  href="/anvandarvillkor"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  användarvillkoren
                </a>
              </p>
            </div>
          </div>
          <br />
          <br />
          <span
            data-testid="register-error-msg"
            className="register-form-error-msg-server"
          >
            {serverError}
          </span>
          <br />
          <br />
          <div className="register-form-button">
            <RoundedButton label={"Skapa konto"}></RoundedButton>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RegisterForm;
