import React from "react";
import "./interestCard.css";
import RoundedLinkButton from "../../../components/Buttons/RoundedLinkButton/RoundedLinkButton";

export default function InterestCard() {

  return (
    <article className={`interest-card`} >
      <div className="interest-card-image">
        <div className="interest-card-image-text">
          <p>Kan vi inte erbjuda dig din drömlokal just nu?</p>
          <p>Ingen fara!</p>
        </div>
      </div>
      <div className="interest-inner-text-card">
        <p>
          Skicka in en lokalförfrågan så letar vi upp en lokal åt er -
          kostnadsfritt!
        </p>

        <RoundedLinkButton
          size={"lg"}
          label={"Skicka in lokalförfrågan"}
          className="interest-responsive-button"
          href={"/forfragan"}
          >
          Skicka in lokalförfrågan
        </RoundedLinkButton>
      </div>
    </article>
  );
}
