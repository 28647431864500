import React, { useEffect } from "react";
import { TermsPage } from "./styles";
import SEOTags from "../../utils/SEOTags";

function Terms() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" }); // scrolls to top on first render
  }, []);

  return (
    <TermsPage>
      <SEOTags
        title={"Användarvillkor - Vakansa"}
        description={"Användarvillkor"}
        canonicalUrl={"/anvandarvillkor"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <div>
        <div className="terms-main-text">
          <h1>Vakansa - Användarvillkor</h1>
          <p>Uppdaterade [2024-07-02].</p>
          <h2>1. Allmänt</h2>
          <p>
            Dessa användarvillkor ("Användarvillkoren") reglerar användningen av
            Tjänsterna (“Tjänsterna”, se vidare p.3 nedan) som tillhandahålls av
            Vakansa AB, org. nr 559214-1161 ("Vakansa" eller "vi"), genom dess
            webbplats<a href="/"> www.vakansa.se</a>, inklusive Vakansas sociala
            medier, och eventuella särskilda villkor som Vakansa tillhandahåller
            separat. Genom att använda Tjänsterna bekräftar Ni att Ni har läst,
            förstått och accepterat Användarvillkoren samt åtar dig/er att följa
            dem. Det rekommenderas att Ni noggrant läser igenom
            Användarvillkoren och skapar en utskrift för framtida referens.
          </p>
          <p>
            Vakansa förbehåller sig rätten att när som helst ändra
            Användarvillkoren. Eventuella ändringar kommer att meddelas i förväg
            via e-post innan de träder i kraft. Om Ni inte samtycker till de nya
            villkoren kommer Ni inte att kunna fortsätta använda Tjänsterna
            efter att ändringarna har trätt i kraft. Det är ditt/ert ansvar att
            hålla dig/er informerad(e) om innehållet i de aktuella
            användarvillkoren.
          </p>

          <h2>2. Hantering av personuppgifter</h2>
          <p>
            Vakansas tillhandahållande av Tjänsterna innebär
            personuppgiftsbehandling. Behandlingen genomförs i enlighet med
            Vakansas personuppgiftspolicy (bilaga 1), som utgör en del av dessa
            Användarvillkor.
          </p>
          <h2>3. Tjänsterna</h2>
          <p>
            Tjänsterna som tillhandahålls av Vakansa inkluderar en
            förmedlingstjänst, benämnd "Vakansa Bas". Genom Vakansa Bas får
            Hyresvärdar ("Lokalägare"), vilka kan vara antingen fastighetsägare
            eller förstahandshyresgäster med äganderätt eller hyresrätt till en
            byggnad och/eller lokal, möjligheten att synliggöra och
            tillgängliggöra sina lokaler för uthyrning. Samtidigt kan
            Hyresgäster ("Lokalsökande"), som kan vara förstahands- eller
            andrahandshyresgäster, söka och hyra de lokaler som Hyresvärdarna
            erbjuder, antingen permanent eller under specifika tidsintervall, så
            kallade "tidsvakanser". Användare på Vakansa.se kan agera både som
            Hyresvärdar och Hyresgäster.
          </p>
          <p>
            Vakansa möjliggör genom Tjänsterna att Hyresvärdar och Hyresgäster
            kan finna varandra för att hyra lokaler, antingen permanent eller
            genom samnyttjande av byggnader och/eller lokaler som Hyresvärdarna
            avser att hyra ut.
          </p>
          <p>
            Utöver Vakansa Bas inkluderar Tjänsterna en utökad
            förmedlingstjänst, benämnd "Vakansa Mest". Denna tjänst innebär inte
            bara de grundläggande funktionerna i Vakansa Bas utan erbjuder även
            konsultation och rådgivning kring lagar och regler, såsom hyreslagar
            och momsregler. Vidare innefattar Vakansa Mest hjälp med utformning
            av avtal för olika typer av andrahandsuthyrning/samnyttjande,
            inklusive tillhandahållande av avtalsmallar. Ytterligare tjänster
            som bokningssystem, hantering av inbetalning av hyra,
            nyckelhantering, städning, personal och en "hot-line" erbjuds. För
            Vakansa Mest upprättas ett separat avtal mellan Vakansa och
            Hyresvärden.
          </p>
          <p>
            Ni accepterar genom användandet av Tjänsterna att Vakansa kan komma
            att ändra, anpassa och modifiera Tjänsterna och dess omfattning,
            design, språk tillgänglighet, programvara eller övriga aspekter som
            är relaterade till Tjänsterna när som helst utan skyldighet att
            förvarna dig/er. I sådant fall förbehåller sig Vakansa också rätten
            att avsluta ditt/ert användarkonto och säga upp avtalet om
            Tjänsterna, vilket i så fall kommer att ske efter att Ni har blivit
            informerad(e) om detta via din/er registrerade e-postadress.
          </p>
          <p>
            Vakansa förbehåller sig rätten att inte tillhandahålla Tjänsterna
            till den som tidigare har brutit mot Användarvillkoren eller
            tidigare av Vakansa tillhandahållna användarvillkor. Den eller de
            fysiska personer som ingår avtal om Tjänsterna för en juridisk
            persons räkning intygar därigenom att denne/de har behörighet att
            binda den juridiska personen till vid var tid gällande
            användarvillkor.
          </p>

          <h2>4. Ångerrätt</h2>
          <p>
            Enligt lag (2005:59) om distansavtal och avtal utanför affärslokaler
            har du som konsument rätt att ångra skapandet av ett användarkonto
            och ingåendet av avtalet om Tjänsterna inom fjorton (14) dagar från
            och med den dagen då användarkontot skapades och avtalet om
            Tjänsterna ingicks. Genom att skapa ett användarkonto och ingå avtal
            om Tjänsterna samtycker du som konsument dock till att Tjänsterna
            påbörjas utföras under ångerfristen om fjorton (14) dagar samt att
            du inte har någon ångerrätt om Tjänsterna fullgörs (till exempel
            förmedling enligt punkt 6.) innan denna ångerfrist löper ut. Detta
            innebär att den angivna ångerfristen blir kortare än fjorton dagar
            om Tjänsterna fullgörs innan denna period har löpt ut. Med att
            Tjänsterna fullgörs avses här att ett skriftligt eller muntligt
            avtal (avtal om uthyrning av annonserad lokal) mellan hyresvärd och
            hyresgäst ingås.
          </p>
          <h2>5. Annonsering</h2>
          <p>
            I Vakansas regler för annonsering (se: bilaga 2 längre ner), som
            utgör en del av dessa Användarvillkor, finns regler som är
            tillämpliga på de annonser som användare (de som har skapat ett
            användarkonto och ingått avtal om Tjänsterna) har möjlighet att
            lägga upp på Webbplatsen. För att en annons ska kunna publiceras
            måste Ni som användare se till att annonsen följer reglerna för
            annonsering. Vakansa förbehåller sig rätten att inte publicera samt
            ta bort annonser som inte följer Användarvillkoren, inklusive
            reglerna för annonsering.
          </p>
          <p>
            All marknadsföring ska enligt Marknadsföringslagen (2008:486) stämma
            överens med god marknadsföringssed, vilket gäller dig som användare.
            Om någon genom ett visst agerande bryter mot reglerna i
            Marknadsföringslagen kan detta agerande komma att anmälas och om
            agerandet fortsätter efter ett sådant förbud kan den som brutit mot
            reglerna även tvingas att betala ett vite. Det är uttryckligen
            förbjudet att skicka reklam via e-mail eller SMS till fysiska
            personer om inte dessa har samtyckt till det på förhand. Det är
            också förbjudet att driva telefonförsäljning till personer som
            tydligt har motsatt sig detta.
          </p>

          <h2>6. Förmedling och förmedlingsavgift m.m.</h2>
          <p>
            När en registrerad användare (Hyresvärd/"Lokalägare") har fått en
            annons för ett objekt publicerad på Webbplatsen och påbörjat
            användningen av Tjänsterna, kan andra användare
            (Hyresgäster/"Lokalsökande") se annonsen och göra en intresseanmälan
            (knappen “Skicka förfrågan” på annonserna) för objektet. En
            intresseanmälan kan också göras genom att Hyresgäst/lokalsökande
            kontaktar Hyresvärd/lokalägare direkt efter att ha upptäckt och läst
            en annons på Vakansas Webbplats. En förmedling räknas både som en
            direkt intresseanmälan genom Webbplatsen eller en intresseanmälan
            som gjorts utanför Webbplatsen men tack vare Webbplatsen.
            Hyresvärden/lokalägaren är skyldig att fråga
            Hyresgästen/lokalsökande hur de fått information om objektet.
          </p>
          <p>
            När en intresseanmälan görs via Vakansa.se skickas denna till
            personal på Vakansa och till användaren som annonserat objektet
            (Hyresvärd) för vidare hantering. Om Hyresvärd och Hyresgäst kommer
            överens om att uthyrning/inhyrning ska ske, upprättas ett skriftligt
            eller muntligt avtal om förhyrning mellan Hyresvärd och Hyresgäst.
            Både Hyresvärden och Hyresgästen är skyldiga att omedelbart
            informera Vakansa om ett avtal om samnyttjande och/eller uthyrning
            ingåtts, samt skicka en kopia och/eller informera Vakansa om det
            skriftliga eller muntliga avtalet. Vakansa tillhandahåller, som en
            del av Vakansa Mest (se p. 3 ovan), konsultation och rådgivning
            rörande dessa avtal på förfrågan (inklusive avtalsmallar) vid behov.
          </p>
          <p>
            OBS!: Om inget avtal ingås där en monetär transaktion görs, utgår
            ingen förmedlingsavgift enligt denna punkt.
          </p>
          <p>
            När undertecknat eller muntligt avtal om uthyrning är Vakansa
            tillhanda, fakturerar Vakansa Hyresvärden en förmedlingsavgift
            motsvarande 15% av hyresbeloppet exklusive moms per månad som
            Hyresvärden fakturerat Hyresgästen. Detta görs varje månad i upp
            till maximalt 12 månader. Med hyresbelopp avses det totala
            hyresbeloppet för hela den avtalade hyresperioden, även om
            hyresperioden är uppdelad i flera kortare hyresavtal inom en 12
            månadersperiod, med start från när Hyresvärden får sin första
            inbetalda hyra från Hyresgästen. Vakansa fakturerar
            förmedlingsavgiften i efterskott med betalningsvillkor om 15 dagar
            från att Hyresvärden fått in sin hyresintäkt, för att säkerställa
            att en förmedlingsavgift går att ta ut. (se längre ner för exempel
            på hur förmedlingsavgiften beräknas). En förmedling anses enligt
            dessa användarvillkor gälla i maximalt 12 månader. Om en förmedlad
            Hyresgäst tecknar ett avtal med Hyresvärden 12 månader efter
            förmedlingen gjordes via Vakansa, utgår ingen förmedlingsavgift. Om
            en förmedlad Hyresgäst tecknar ett avtal med Hyresvärden 11 månader
            efter förmedlingen gjordes via Vakansa, utgår förmedlingsavgiften i
            12 månader enligt användarvillkoren.
          </p>
          <p>
            Pris för utnyttjande av Vakansa Mest (se p. 3 ovan) lämnas separat
            på förfrågan. Vakansa tar inte ut någon avgift för att skapa ett
            användarkonto och ingå avtal om Tjänsterna eller för att annonsera
            via Webbplatsen. I vissa fall kan Vakansa erbjuda hyresvärdar som
            önskar betala för annonsering en fast avgift. Denna information
            kommer då att offentliggöras som ett erbjudande till befintliga och
            nya användare på Webbplatsen/hemsidan här. Vakansa bibehåller sig
            rätten att ta ut avgifter för att en användare inte har följt
            Användarvillkoren (se punkt, 10) eller annan liknande omständighet
            (t.ex. påminnelseavgift och dröjsmålsränta).
          </p>
          <br />
          <br />
          <p>EXEMPEL PÅ BERÄKNING AV FÖRMEDLINGSAVGIFT VAKANSA BAS:</p>
          <p>
            Bokning av lokal, flexibel hyra 10 timmar á 100 kr.
            <br />
            <br />
            • Summa hyresbelopp: 10 timmar x 100 kr = 1 000 kr.
            <br />
            • Förmedlingsavgift 15%: 1 000 kr x 0,15 = 150 kr.
            <br />
            • Förmedlingsavgift inkl moms till Vakansa: 150 kr x 1,25 (25% moms)
            = 187,5 kr
            <br />
            • Hyresbelopp till hyresvärden för momsbefriad lokal: 1000 kr -
            187,5 kr = 812,5 kr
            <br />
            • Hyresbelopp till hyresvärden för lokal inkl moms: 1000 kr x 1,25 -
            187,5 kr = 1062,5
            <br />
          </p>
          <br />
          <br />
          <p>
            Bokning av lokal i flera månader och/eller flera avtal inom en 12
            månadersperiod från första uthyrning, dvs flexibel hyra 10 timmar á
            100 kr.
            <br />
            <br />
            • Summa hyresbelopp Februari (månad 1): 10 timmar x 100 kr = 1 000
            kr.
            <br />
            • Summa hyresbelopp Maj (månad 4): 10 timmar x 100 kr = 1 000 kr.
            <br />
            • Summa hyresbelopp Augusti (månad 7): 10 timmar x 100 kr = 1 000
            kr.
            <br />
            • Summa hyresbelopp December (månad 11): 10 timmar x 100 kr = 1 000
            kr.
            <br />
            • Summa alla hyresbelopp under hela hyresperioden inom en 12
            månadersperiod från första uthyrningen: 4000 kr.
            <br />
            • Förmedlingsavgift 15%: 4 000 kr x 0,15 = 600 kr.
            <br />
            • Förmedlingsavgift inkl moms till Vakansa: 600 kr x 1,25 (25% moms)
            = 750 kr
            <br />
            • Hyresbelopp till hyresvärden för momsbefriad lokal: 4000 kr - 750
            kr = 3250 kr
            <br />
            • Hyresbelopp till hyresvärden för lokal inkl moms: 4000 kr x 1,25 -
            750 kr = 4250 kr
            <br />
          </p>
          <br />
          <br />
          <p>
            Bokning av byggnad eller lokal, fast hyra 4 000 kr per månad i 18
            månader.
            <br />
            <br />
            • Summa hyresbelopp: 4 000 kr x 18 månader = 72 000 kr.
            <br />
            • Summa hyresbelopp som Vakansa baserar sin förmedlingsavgift på:
            4000 kr x 12 månader (upp till 12 månader) = 48 000 kr.
            <br />
            • Förmedlingsavgift 15%: 48 000 kr x 0,15 = 7 200 kr.
            <br />
            • Moms: 7 200 kr x 0,25 = 1 800 kr.
            <br />
            • Förmedlingsavgift inkl moms till Vakansa: 7200 kr x 1,25 (25%
            moms) = 9 000 kr
            <br />
            • Hyresbelopp till hyresvärden för momsbefriad lokal: 72 000 kr -
            9000 kr = 63 000 kr
            <br />
            • Hyresbelopp till hyresvärden för lokal inkl moms: 48 000 kr x 1,25
            - 9000 kr = 81 000kr
            <br />
          </p>
          <br />
          <p>
            OBS! Priser på Vakansa.se och för våra Tjänster är alltid exklusive
            moms.
          </p>

          <h2>7. Dröjsmålsränta</h2>
          <p>
            Om betalningen av förmedlingsavgift enligt punkt 6 ovan inte
            fullgörs vid förfallodagen, åläggs hyresvärden att betala
            dröjsmålsränta. Dröjsmålsräntan beräknas som åtta procent (8%) plus
            den aktuella referensräntan* på det förfallna beloppet, och den ska
            gälla fram till dess att fullständig betalning sker.
          </p>
          <br />
          <p>
            * Referensräntan bestäms genom ett beslut av Riksbanken två gånger
            per år, den sista vardagen före ingången av nytt kalenderhalvår.
            Beslut om referensräntan publiceras därmed dels sista vardagen före
            den 1 januari, dels sista vardagen före den 1 juli.
          </p>

          <h2>8. Ditt ansvar som användare</h2>
          <p>
            Oavsett om Ni är Hyresvärd eller Hyresgäst ansvarar Ni för att
            använda Tjänsterna i enlighet med Användarvillkoren och andra
            tillämpliga villkor på Webbplatsen samt gällande lagstiftning. Vid
            användning av Tjänsterna åtar Ni er att inte:
          </p>
          <p>
            • <b>Strida mot användarvillkoren:</b> Använda Tjänsterna på ett
            sätt som strider mot Användarvillkoren och andra relevanta villkor
            på Webbplatsen samt gällande lagstiftning.
          </p>
          <p>
            • <b>Obehörig användning:</b> Kopiera, lagra eller på annat sätt
            tillskansa dig/er information från Webbplatsen för ändamål som inte
            uttryckligen tillåts enligt Användarvillkoren.
          </p>
          <p>
            • <b>Spam eller oönskad information:</b> Sprida oönskade meddelanden
            ("spam"), oönskad information eller reklam som inte rör uthyrning av
            byggnader, lokaler och arbetsplatser via mail eller chattfunktioner.
          </p>
          <p>
            • <b>Datainsamling:</b> Inhämta data eller annan information från
            Webbplatsen i syfte att skapa eller sammanställa en databas, katalog
            eller liknande.
          </p>
          <p>
            • <b>Manipulering eller otillåten användning:</b> Manipulera eller
            använda icke-offentliga områden av Webbplatsen eller Vakansas
            leveranssystem.
          </p>
          <p>
            • <b>Kontakt vid förmedling:</b> Ta kontakt med hyresgäster eller
            hyresvärdar genom Vakansa om Ni är mäklare och har
            provisionsbaserade uppdrag mot hyresgäster och/eller hyresvärdar.
            Vakansa har redan förmedlingsuppdrag avseende de objekt som finns
            publicerade på Webbplatsen. Har Ni en intressent på ett specifikt
            objekt ber vi dig/er istället kontakta Vakansa direkt.
          </p>
          <p>
            • <b>Att besvara våra kontrollfrågor:</b> För att vi på Vakansa ska
            kunna upprätthålla vårt ansvar (i enlighet med punk. 9) åtar Ni er
            som användare av våra tjänster att besvara våra kontrollfrågor om
            hur uthyrningsprocessen med förmedlat part fortskrider. Om du som
            Hyresvärd underlåter dig att svara, utgår en straffavgift (i
            enlighet med punk. 10). Om du som Hyresgäst underlåter dig att svara
            efter tre (3) kontaktförsök kommer en straffavgift om 500 kr
            debiteras din organisation.
          </p>
          <p>
            Ni ansvarar vidare för att användarspecifik information, såsom
            inloggningsuppgifter och lösenord, som kan användas för åtkomst till
            Tjänsterna, förvaras på ett tryggt sätt och inte används av eller
            avslöjas för någon obehörig person. I det fall Ni misstänker att
            någon obehörig person fått tillgång till din/er användarspecifika
            information eller att Tjänsterna på annat sätt missbrukas, ansvarar
            Ni för att genast ändra ditt/ert lösenord och ta kontakt med
            Vakansa.
          </p>
          <p>
            Vakansa ansvarar inte för skador som uppkommit genom att Ni har
            hanterat uppgifterna på ett oaktsamt sätt.
          </p>
          <p>
            Om Ni publicerar information och bilder i en annons eller på
            Webbplatsen ansvarar Ni för innehållet i denna information och över
            bilderna. I det fall du publicerar information som innehåller
            personuppgifter som berör dig/er själv(a) samtycker Ni genom
            publicerandet till att Vakansa behandlar dessa personuppgifter
            enligt Vakansas personuppgiftspolicy.
          </p>
          <p>
            Skulle en situation uppstå, där Vakansa förmedlar kontakt med en
            hyresgäst som hyresvärden sedan tidigare redan har haft kontakt med
            på egen hand, skall ingen förmedlingsavgift betalas till Vakansa, om
            följande villkor är uppfyllda: Den tidigare kontakten mellan
            hyresvärd och hyresgäst har gjorts mindre än en månad innan Vaknsa
            förmedlade kontakten, och hyresvärden kan återkomma till Vakansa med
            bevisning om detta. Ni har 48 timmar på er att lämna bevis, annars
            utgår förmedlingsavgift enligt våra villkor för tjänsten. Har mer än
            en månad förflutit mellan den tidigare kontakten mellan hyresvärd
            och hyresgäst och Vakansas förmedling, skall det anses vara en ny
            kontakt och hyresvärden skall betala förmedlingsavgift till Vakansa
            ifall hyresgästen inleder hyra av lokalen.
          </p>

          <h2>9. Vakansas ansvar</h2>
          <p>
            Vakansa strävar efter att tillhandahålla Tjänsterna med stabilitet.
            Tjänsterna tillhandahålls dock i befintligt skick och utan några
            garantier eller utfästelser av något slag. Vakansa förbehåller sig
            rätten att uppdatera, revidera, eller temporärt eller permanent
            upphöra med att tillhandahålla Tjänsterna.
          </p>
          <p>
            Vakansa ansvarar inte för eventuella skador, inklusive indirekt
            skada, som kan uppkomma till följd av eventuella och/eller brister i
            Tjänsterna eller för att Ni inte kan tillgå Tjänsterna. Vakansa
            ansvarar inte heller för innehållet på webbplatser som länkats till
            eller från Tjänsterna eller Webbplatsen. Vakansa har som policy att
            granska allt material som läggs ut på Webbplatsen och förbehåller
            sig rätten att inte godkänna material eller ändra information som en
            användare publicerar på Webbplatsen.
          </p>
          <p>
            Vakansa ansvarar inte för skador som uppkommer i lokaler och på
            fönster, skyltfönster, dörrar eller portar som hör till lokaler
            eller som finns i direkt anslutning till lokaler. Detta gäller
            oavsett vem eller vad som orsakat skadan. Varje hyresvärd och
            hyresgäst ansvarar för sitt eget försäkringsbehov.
          </p>
          <p>
            Vakansa har regelbunden kontakt och gör regelbundna avstämningar med
            hyresvärdar och hyresgäster, men det är alltid upp till varje
            hyresgäst respektive hyresvärd att själva avgöra om de vill ingå ett
            avtal med varandra. Vakansa ansvarar inte för de objekt som
            utannonseras utan förmedlar endast dessa mellan hyresvärdar och
            hyresgäster. Vakansa ansvarar inte för avtal som skrivs mellan
            Hyresgäst och Hyresvärd rörande användning eller uthyrning av
            lokaler.
          </p>
          <p>
            Genom att använda Tjänsterna samtycker Ni uttryckligen till att Ni
            använder Tjänsterna på egen risk och att Tjänsterna kan påverka
            användbarheten hos programvaror och applikationer från andra
            tillverkare. Ni bekräftar vidare att Ni är medveten/medvetna om att
            Tjänsterna tillhandahålls i befintligt skick utan några som helst
            garantier. I den maximala utsträckning som tillåts enligt tillämplig
            lag frånsäger sig Vakansa härmed alla garantier gällande Tjänsterna,
            både underförstådda och uttryckliga, med avseende på Tjänsternas
            innehåll, tillgänglighet, kvalitet eller lämplighet för ett visst
            syfte.
          </p>
          <p>
            Om Ni har problem eller är missnöjd med Tjänsterna eller Webbplatsen
            kan Ni vända dig/er till oss via info@vakansa.se och/eller välja att
            sluta använda Tjänsterna.
          </p>

          <h2>10. Uppsägning av villkoren/avsluta konto</h2>
          <p>
            Dessa Användarvillkor förblir gällande så länge Ni innehar ett
            användarkonto hos Vakansa, det vill säga under den tid då Ni har
            ingått avtal om Tjänsterna. Om Ni inte längre önskar vara bundna av
            Användarvillkoren måste Ni avsluta ditt/ert användarkonto och säga
            upp avtalet om Tjänsterna.
          </p>
          <p>
            Vakansa förbehåller sig rätten att, utan ansvar, avsluta ditt/ert
            användarkonto och säga upp avtalet om Tjänsterna med omedelbar
            verkan, om ditt/ert användande av Tjänsterna strider mot
            Användarvillkoren, gällande rätt, eller om Ni missbrukar Tjänsterna.
            Vid överträdelse av dessa Användarvillkor eller gällande rätt genom
            Tjänsterna, eller vid missbruk av Tjänsterna, är Ni skyldig(a) att
            ersätta Vakansa och hålla Vakansa skadeslöst från all skada som
            orsakats därav. Detta gäller även vid upprepad underlåtenhet att
            svara i kommunikationen med Vakansa eller mellan hyresgäst och
            hyresvärd.
          </p>
          <p>
            När ditt/ert användarkonto avslutas kommer Vakansa omedelbart att ta
            bort all tillhandahållen och lagrad information om dig/er, förutom
            information som Vakansa enligt lag är skyldig att behålla.
          </p>
          <p>
            Om ett användarkonto avslutas efter en förmedling och/eller i syfte
            att undgå betalning av förmedlingsavgift efter en förmedling utförd
            av Vakansa, och/eller om en användare inte återkopplar till Vakansa
            för att informera om att ett avtal har ingåtts enligt Vakansas
            tjänster (se punkt 8), kommer en straffavgift om 5000 kr exklusive
            moms att debiteras utöver förmedlingsavgiften. Straffavgiften kommer
            att debiteras efter tre (3) kontaktförsök med registrerad användare
            och/eller organisation och skickas direkt till den registrerade
            organisationen och/eller användaren, med användare som referens.
            Efter erlagd straffavgift kommer Vakansa även att begära att
            användaren uppfyller sitt ansvar enligt användarvillkoren och
            informerar om hyresavtalet, för att därefter erlägga gällande
            förmedlingsavgift enligt de aktuella användarvillkoren.
          </p>

          <h2>11. Användargenererat innehåll och immateriella rättigheter</h2>

          <p>
            Med användargenererat innehåll avses allt sådant innehåll som en
            användare skapar och/eller publicerar på Webbplatsen, som t.ex.
            bilder, filmer och annonstexter ("Användargenererat innehåll").
          </p>

          <p>
            Ni garanterar att Ni innehar samtliga nödvändiga rättigheter till
            det Användargenererade innehållet, antingen genom att Ni själv(a)
            har skapat detta (vare sig det är exempelvis en bild, en film eller
            en annonstext), eller att alla som har medverkat till skapandet av
            innehållet har gett dig/er tillstånd att använda det på Webbplatsen
            i enlighet med Användarvillkoren. Detta innebär att Ni garanterar
            att Användargenererat innehåll inte innehåller immaterialrättsligt
            skyddat material, såsom musik, film, bild, logotyp eller annat
            material som Ni inte har tillåtelse att använda.
          </p>
          <p>
            Ni garanterar vidare att Ni har sett till att de personer som kan
            identifieras i det Användargenererade innehållet (exempelvis i en
            bild eller en film eller enbart genom sitt namn) är medvetna om hur
            materialet kommer att användas och att de har godkänt att medverka i
            det Användargenererade innehållet samt att Vakansa även kan komma
            att använda detta Användargenererade innehåll i
            marknadsföringssyfte.
          </p>
          <p>
            Genom att publicera Användargenererat innehåll på Webbplatsen ger Ni
            Vakansa en rätt att förfoga över detta Användargenererade innehåll,
            genom att till exempel bearbeta, formatanpassa, lagra eller kopiera
            det samt göra det tillgängligt för allmänheten (oavsett mediekanal)
            och att vidareupplåta dessa rättigheter till eventuella
            samarbetspartners. Vakansa får även använda Användargenererat
            innehåll i marknadsföringssyfte. Vakansas rättigheter kvarstår även
            efter att en annons har tagits bort från Webbplatsen.
          </p>
          <p>
            Ni efterger härmed alla krav på ersättning från Vakansa för Vakansas
            användning av det Användargenererade innehållet.
          </p>
          <p>
            Vakansa ansvarar inte för sakuppgifter i annonser som läggs upp på
            Webbplatsen. Om Vakansa anser att innehållet i en annons på
            Webbplatsen är olämpligt på något sätt förbehåller sig Vakansa
            rätten att ta bort annonsen. Vid en sådan situation kommer Vakansa
            kontakta dig i förväg och ge dig/er möjlighet att ändra innehållet i
            annonsen.
          </p>
          <p>
            Samtliga tekniska lösningar och immateriella rättigheter avseende
            Tjänsterna är Vakansas egendom alternativt egendom tillhörande
            tredje part med vilken Vakansa samarbetar och får inte användas av
            dig/er utöver vad som krävs för Tjänsternas användande enligt dessa
            Användarvillkor. Under förutsättning att Ni följer dessa
            Användarvillkor och såvida inget annat anges i dessa Användarvillkor
            ger Vakansa dig/er en icke exklusiv, icke överförbar, begränsad rätt
            att använda Tjänsterna för eget bruk. Det sistnämnda innebär t.ex.
            att Ni inte får upplåta och/eller överlåta hela eller delar av
            Tjänsterna till tredje man utan att i förväg inhämta Vakansas
            skriftliga godkännande.
          </p>
          <h2>12. Cookies</h2>
          <p>
            Webbplatsen tar hjälp av små textfiler (cookies) för att skapa en
            bra användarupplevelse för dig som besökare.
          </p>
          <p>
            Syftet med cookies är att kunna minnas hur Ni interagerar med
            Webbplatsen och vilken typ av enhet (t.ex. smartphone eller
            surfplatta) som du använder. Med hjälp av denna information kan
            Vakansa exempelvis utveckla och anpassa Webbplatsens innehåll och
            funktionalitet optimalt för dig/er som användare.
          </p>
          <p>
            Ni kan när som helst välja att stänga av eller radera cookies i er
            webbläsare. För att ändra cookieinställningarna kan Ni gå längst ned
            på sidan och klicka på 'Cookieinställningar'. Om Ni har fler frågor
            om cookies, är Ni välkomna att kontakta oss via info@vakansa.se.
          </p>
          <p>
            Vakansa nyttjar cookies från tredje part för att samla in statistik
            i samlad/aggregerad form i analysverktygen Google Analytics
            (Alphabet Inc.) och Hotjar (Hotjar Ltd). De cookies som används är
            både permanenta och tillfälliga cookies (sessionscookies). Dessa
            permanenta cookies sparas som filer på din/er dator eller mobila
            enhet i högst 24 månader.
          </p>
          <h2>13. Övrigt</h2>
          <p>
            På dessa Användarvillkor ska svensk lag tillämpas. Varje tvist,
            tolkning eller tillämpning som härrör från, i samband med eller i
            relation med dessa villkor och/eller Tjänsten ska behandlas i svensk
            domstol.
          </p>
          <p>
            I händelse av att vissa av Användarvillkorens bestämmelser bedöms
            vara ogiltiga enligt gällande svensk lag av behörig domstol ska
            övriga bestämmelser villkor fortsätta att gälla.
          </p>
          <p>
            Vid tvist har en användare som är konsument möjlighet att vända sig
            till Allmänna reklamationsnämnden, som är en nämnd för alternativ
            tvistelösning, Box 174, 101 23 Stockholm, www.arn.se, telefon 08 –
            508 860 00. En anmälan till nämnden måste vara skriftlig. För att
            nämnden ska pröva ärendet finns bland annat vissa värde- och
            tidsgränser. Vakansa åtar sig att medverka i Allmänna
            reklamationsnämndens behandling av tvisten. En användare har även
            möjlighet att diskutera ärendet med Konsumenternas Bank- och
            Finansbyrå samt konsumentvägledaren i användarens hemkommun.
          </p>
          <h1>Bilaga 1 - Personuppgiftspolicy</h1>
          <h2>Allmänt</h2>
          <p>
            Vakansa AB, org. nummer 559214-1161, (“Vakansa”) erbjuder vissa
            tjänster (“Tjänsterna”) rörande samnyttjande av byggnader och
            lokaler till hyresvärdar och hyresgäster som skapar ett
            användarkonto hos Vakansa och tecknar avtal om Tjänsterna. I denna
            policy beskrivs hur Vakansa behandlar sina användares
            personuppgifter, dvs. uppgifter som en användare anger när denne
            skapar ett användarkonto och ingår avtal om Tjänsterna.
          </p>
          <p>
            Din personliga integritet är viktig för Vakansa och Vakansa strävar
            alltid efter att skydda dina personuppgifter på bästa sätt. Denna
            personuppgiftspolicy beskriver vilken slags information Vakansa
            behandlar om dig, hur Vakansa får informationen, hur den används,
            hur den delas och vilka åtgärder som har vidtagits för att skydda
            dina personuppgifter. Av denna personuppgiftspolicy framgår också
            vilka rättigheter du har beträffande dina personuppgifter.
          </p>
          <h2>Vem ansvarar för dina personuppgifter?</h2>
          <p>
            Vakansa är personuppgiftsansvarig för de personuppgifter som du
            skickar till oss via Vakansas webbplats, www.vakansa.se, när du
            skapar ett användarkonto samt tecknar avtal om Tjänsterna, och
            ansvarar för dina personuppgifter i enlighet med den Allmänna
            dataskyddsförordningen ("GDPR").
          </p>
          <h2>Var lagras dina personuppgifter?</h2>
          <p>
            De uppgifter som Vakansa samlar in om dig lagras inom det europeiska
            ekonomiska samarbetsområdet (EES) i Frankfurt (EU-central-1).
          </p>
          <h2>Vad för slags personuppgifter samlar Vakansa in?</h2>
          <p>
            Vakansa samlar in de personuppgifter som du skickar till oss i
            samband med att du skapar ett konto på Vakansas webbplats och ingår
            avtal om Tjänsterna. Dessa personuppgifter innefattar följande
            information:
          </p>
          <br />
          <p>• För- och efternamn,</p>
          <br />
          <p>• Företagsnamn och organisationsnummer,</p>
          <br />
          <p>• Adress,</p>
          <br />
          <p>• Telefonnummer, samt</p>
          <br />
          <p>• E-postadress.</p>
          <h2>Hur använder Vakansa dina personuppgifter?</h2>
          <p>Vakansa samlar in personuppgifterna ovan för följande ändamål:</p>
          <br />
          <p>
            - För att kunna kontrollera samt säkerställa att de som använder
            Tjänsterna har rätt att göra det.
          </p>
          <p>
            - För att kunna skicka e-post till dig som användare gällande objekt
            som du publicerat eller bevakar.
          </p>
          <p>
            - För att kunna kontakta dig som användare gällande supportärenden.
          </p>
          <p>
            - För att vidta åtgärder i syfte att åtgärda buggar och liknande
            samt för att göra Tjänsterna mer användarvänliga och för att främja
            forskning och utveckling av delade lokaler.
          </p>
          <p>
            - För att kunna skicka marknadsföringserbjudanden och information
            som rör Tjänsterna och Vakansas verksamhet via e-post.
          </p>
          <p>
            Dina personuppgifter kommer inte att användas på ett sätt som är
            oförenligt med det ändamål som personuppgifterna samlades in för.
          </p>
          <p>
            Vakansa behandlar och/eller sparar dina personuppgifter så länge som
            det behövs för att uppfylla ovan ändamål eller, i tillämpliga fall,
            så länge som Vakansa enligt lag, förordning eller
            myndighetsföreskrift är skyldiga att göra detta eller för att
            behålla personuppgifter som måste behållas, t.ex. i syfte att lösa
            en tvist. När personuppgifterna inte längre behövs för ovan nämnda
            ändamål kommer de att raderas.
          </p>
          <h2>Hur dina uppgifter delas</h2>
          <p>
            Dina uppgifter kan komma att delas med tredje part. Dock inte på
            något annat sätt än vad som beskrivs nedan.
          </p>
          <p>
            - Tjänsteleverantörer. Vakansa använder sig av tredje parter i syfte
            av att tillhandahålla Tjänsterna till dig och dessa kan behöva
            behandla eller få tillgång till personuppgifter för att kunna utföra
            sina tjänster gentemot Vakansa. När Vakansa inom ramen för
            Tjänsterna använder sig av tjänsteleverantörer ingår Vakansa
            personuppgiftsbiträdesavtal och/eller vidtar andra lämpliga åtgärder
            för att säkerställa att dina personuppgifter behandlas på ett sätt
            som överensstämmer med denna personuppgiftspolicy.
          </p>
          <p>
            - Samarbetspartners. Vakansa samarbetar med tredje parter i syfte
            att göra Tjänsterna bättre mer användarvänliga och för att främja
            forskning och utveckling av delade lokaler och dessa
            samarbetspartners kan behöva behandla eller få tillgång till
            personuppgifter för att kunna bistå Vakansa. När Vakansa samarbetar
            med tredje parter ser Vakansa till att endast dela de
            personuppgifter som behövs för ändamålet samt om möjligt i
            anonymiserad eller pseudonymiserad form. Vidare ingår Vakansa
            personuppgiftsbiträdesavtal och/eller vidtar andra lämpliga åtgärder
            för att säkerställa att dina personuppgifter behandlas på ett sätt
            som överensstämmer med denna personuppgiftspolicy.
          </p>
          <p>
            - Lagstadgad skyldighet. Vakansa kan komma att dela dina
            personuppgifter i de fall då Vakansa är skyldiga att göra det enligt
            lag.
          </p>
          <p>
            Vakansas mål är att enbart behandla dina personuppgifter inom
            EU/EES. Vakansa kan dock, i vissa fall, komma att överföra dina
            personuppgifter till länder utanför EU/EES. I det fall en sådan
            överföring sker så åtar sig Vakansa att se till att överföringen
            sker i enlighet med gällande rätt och att dina personuppgifter är
            skyddade även efter överföringen.
          </p>
          <p>
            Vid överföringar till ett land utanför EU/EES som inte säkerställer
            en tillräcklig skyddsnivå för personuppgifter använder sig Vakansa
            av EU-kommissionens standardavtalsklausuler för överföring av
            personuppgifter till land utanför EU/EES. Du finner dessa{" "}
            <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en">
              {" "}
              här
            </a>
          </p>
          <h2>Vårt ansvar för dina rättigheter</h2>
          <p>
            Vakansa ansvarar för att dina personuppgifter används i enlighet med
            gällande rätt och att dina rättigheter får effekt. Skulle du vilja
            utnyttja dina rättigheter så är du närsomhelst välkommen att
            kontakta Vakansa. Kontaktuppgifter finns längst ner i denna
            personuppgiftspolicy.
          </p>
          <p>
            Du kan förvänta dig ett svar från oss inom en månad från den
            tidpunkt då du kontaktar Vakansa. Skulle din begäran vara
            komplicerad eller om vi samtidigt har fått in ett stort antal
            begäranden har Vakansa rätt att förlänga tiden med två månader till.
            Om Vakansa inte anser sig kunna uppfylla din begäran är Vakansa
            skyldiga att senast inom en månad från det att Vakansa tog emot din
            begäran meddela dig varför Vakansa inte kan göra det du vill att
            Vakansa ska göra och informera dig om att du har rätt att klaga hos
            tillsynsmyndigheten.
          </p>
          <p>
            Det är kostnadsfritt för dig att utnyttja dina rättigheter. Om det
            du begär med anledning av dina rättigheter däremot är orimligt eller
            uppenbart ogrundat har Vakansa rätt att ta ut en administrativ
            avgift för att ge dig informationen eller genomföra efterfrågad
            åtgärd, eller vägra att tillmötesgå din begäran.
          </p>
          <h2>Vilka är dina rättigheter?</h2>
          <p>
            Du har ett antal rättigheter beträffande behandlingen av dina
            personuppgifter som du kan göra gällande. Vill du göra någon eller
            några av dessa rättigheter gällande ska du kontakta Vakansa.
          </p>
          <p>
            Du har rätt att kostnadsfritt begära tillgång till samt åtkomst till
            kopia av dina personuppgifter (s k registerutdrag), begära rättelse
            av felaktiga uppgifter och, under vissa omständigheter, begära att
            dina personuppgifter raderas. Du har i vissa fall även rätt att
            begära att behandlingen av dina personuppgifter begränsas, t.ex.
            under tiden det åligger oss att utreda eventuella invändningar från
            dig.
          </p>
          <p>
            Du har rätt att i vissa fall invända mot Vakansas behandling av dina
            personuppgifter. Om du invänder får dina personuppgifter inte
            behandlas om inte Vakansa kan visa tvingande berättigade skäl som
            överväger tyngre ditt intresse av att inte få personuppgifterna
            behandlade.
          </p>
          <p>
            Du har i vissa fall rätt att få ut personuppgifter som
            tillhandahållits från dig, i ett strukturerat, allmänt använt och
            maskinläsbart format samt att kunna överföra dessa till annan
            personuppgiftsansvarig (s.k. dataportabilitet). Detta gäller
            exempelvis om Vakansa behandlar dina personuppgifter för att det är
            nödvändigt för att uppfylla ett avtal med dig.
          </p>
          <p>
            Du har rätt att framföra eventuella klagomål på Vakansas behandling
            av dina personuppgifter till Datainspektionen, som är den myndighet
            i Sverige som utövar tillsyn över hur företag som Vakansa följer
            personuppgiftslagstiftningen.
          </p>
          <h2>Vem har tillgång till dina personuppgifter?</h2>
          <p>
            Endast personal på Vakansa samt de tredje parter som Vakansa enligt
            ovankan komma att dela dina personuppgifter vilka behöver ha
            tillgång till dina personuppgifter för att Vakansa ska kunna
            tillhandahålla Tjänsterna till dig har eller kan komma att få
            tillgång till dina personuppgifter. Vakansa vidarebefordrar, säljer
            eller byter aldrig dina personuppgifter för marknadsföringsändamål
            till tredje part.
          </p>
          <h2>Hur skyddar Vakansa dina personuppgifter?</h2>
          <p>
            Vakansa har vidtagit lämpliga säkerhetsåtgärder för att skydda dina
            personuppgifter mot otillbörlig access, förändring och radering. Om
            det inträffar säkerhetsincidenter som kan påverka dig eller dina
            personuppgifter på ett mer betydande sätt, t.ex. när det finns en
            risk för bedrägeri eller identitetsstöld, kommer Vakansa att höra av
            oss till dig och berätta vad du kan göra för att minska risken.
          </p>
          <h2>Cookies</h2>
          <p>
            Vakansas webbplats samt Vakansas sociala medier tar hjälp av små
            textfiler (cookies) för att skapa en bra användarupplevelse för dig
            som besökare.
          </p>
          <p>
            Syftet med cookies är att kunna minnas hur du interagerar med
            Vakansas webbplats och/eller sociala medier och vilken typ av enhet
            (t.ex. smartphone eller surfplatta) som du använder. Med hjälp av
            denna information kan Vakansa exempelvis utveckla och anpassa
            Vakansa webbplats och sociala mediers innehåll och funktionalitet
            optimalt för dig som användare.
          </p>
          <p>
            För mer information om hur vi använder cookies, se avsnittet om
            cookies på Vakansas webbplats www.vakansa.se.
          </p>
          <h2>Kontaktinformation</h2>
          <p>
            Om Ni undrar över hur Vakansa använder dina personuppgifter, vill
            utnyttja dina rättigheter eller har frågor i övrigt om detta
            Integritetsmeddelande, vänligen kontakta Vakansa via
            info@vakansa.se.
          </p>
          <h1>Bilaga 2 - Regler för annonsering</h1>
          <p>
            Vakansa gör inte anspråk på att nedan angiven information, med
            avseende på vad som kan anses vara olagligt eller otillbörligt och
            således inte får förekomma på Webbplatsen, är komplett eller
            uttömmande. Informationen är således i detta avseende endast
            exemplifierande. Ni ansvarar själv som användare för att den
            information som Ni lägger in i en annons inte strider mot vid var
            tid gällande rätt. Ni som användare är ansvarig för din annons och
            dess innehåll.
          </p>
          <h2>Publiceringstid</h2>
          <p>
            Efter att annonsen har godkänts och publicerats på Webbplatsen måste
            Ni som användare ta bort annonsen så snart syftet med annonseringen
            är uppfyllt, t.ex. när Ni har hyrt ut det objekt som Ni har
            annonserat om.
          </p>
          <h2>Granskning och kontroll av annonser och användare</h2>
          <p>
            Vakansa förbehåller sig rätten att granska samtliga annonser och att
            neka eller ta bort en annons på grund av att den bryter mot de
            Användarvillkoren (inklusive dessa regler för annonsering), tredje
            parts upphovsrätt samt annan vid var tid gällande rätt. Vakansa
            förbehåller sig även rätten att ändra i en annons om den bryter mot
            dessa regler för annonsering men, efter att en ändring har gjorts,
            kan publiceras.
          </p>
          <p>
            Vakansa genomför regelbundet kontroller av användare, till exempel
            för identitetsverifiering. Om en användare inte medverkar till sådan
            kontroll har Vakansa rätt att avsluta dennes användarkonto och säga
            upp avtalet om Tjänsterna.
          </p>
          <h2>Språk</h2>
          <p>
            Endast annonser på svenska, danska, norska eller engelska är
            tillåtna.
          </p>
          <h2>Inte ren marknadsföring</h2>
          <p>
            Det är endast tillåtet att annonsera om uthyrning av byggnad
            och/eller lokal eller del därav. Annonstexten ska användas till att
            beskriva objektet som erbjuds i annonsen. Övrig information, såsom
            beskrivning av en verksamhet, ett generellt utbud eller
            kontaktuppgifter till den som hyr ut en byggnad och/eller lokal, är
            inte tillåtet att ha i annonstexten. Detta tolkas som ett försök att
            kringgå Vakansas tjänster och upptäckten av en annons med
            information där förmedling kan göras utanför vakansas Tjänster
          </p>
          <h2>Beskrivning</h2>
          <p>
            Annonsrubriken ska beskriva objektet, inga företagsnamn eller länkar
            får förekomma. Annonstexter får inte kopieras från andra annonser då
            dessa kan vara skyddade av upphovsrätt och/eller annan vid var tid
            gällande rätt. Det är inte tillåtet att använda sig av sådana sökord
            i annonstexten som gör att användare får felaktiga sökträffar eller
            som innebär otillåten användning av annans varumärke.
          </p>
          <h2>Inga dubbletter</h2>
          <p>
            Det är inte tillåtet att lägga in flera annonser om ett och samma
            objekt samtidigt. Det är inte heller tillåtet att lägga upp annonser
            avseende ett och samma objekt under flera län eller i mer än en
            kategori samtidigt.{" "}
          </p>
          <h2>Stötande och kränkande innehåll</h2>
          <p>
            Annonser som kan verka stötande eller kränkande för folkgrupper
            och/eller enskilda individer godkänns inte för publicering. Bilder
            och filmer med kränkande innehåll tillåts inte.
          </p>
          <h2>Tredjepartsinformation</h2>
          <p>
            När Ni lägger upp en annons kan Vakansa visa tilläggsinformation i
            anslutning till din/er annons. Sådan tilläggsinformation visas
            automatiskt och är baserad på den information som du/er som
            användare tillhandahåller, exempelvis kan kartinformation visas
            baserat på det postnummer Ni har angett. Dessa tilläggsuppgifter
            hämtas från externa system som tillhandahålls av tredje part och
            Vakansa tar inget ansvar för eventuella felaktigheter i sådan
            tilläggsinformation. Ni måste själv kontrollera att dessa uppgifter
            är korrekta. Meddela gärna Vakansa via info@vakansa.se om någon
            tilläggsinformation inte stämmer.
          </p>
          <h2>Länkar och hänvisningar</h2>
          <p>
            Länkar och hänvisningar i annonser ska vara relevanta för objektet
            Ni annonserar om. Samma regler gäller för länkarna som för annonsen
            i övrigt, dvs. Ni som användare kan komma att ansvara för innehållet
            på den webbsida Ni länkar till. Vidare är det inte tillåtet att
            länka eller referera till en annan annons- eller auktionswebbplats,
            webbplats eller telefonnummer och/eller mailadress, i beskrivingen
            av annonsen, där en anmälan och/eller bokning kan göras direkt i
            syfte att kringgå Vakansas Tjänster - Upptäcks detta utgår en
            straffavgift enligt punkt 10 i dessa användarvillkor, om inte detta
            åtgärdas efter en varning. Det är inte tillåtet att länka eller
            hänvisa till hemsidor där relevant information är dold bakom
            medlemskap eller på annat vis är lösenordsskyddad.
          </p>
          <h2>Bilder och filmer</h2>
          <p>
            Bilder och filmer i en annons ska vara relevanta för objektet Ni
            annonserar om och får inte innehålla övrig text, företagsnamn,
            logotyper, URL:er eller andra grafiska tillägg som kan anses utgöra
            marknadsföring. Det är inte heller tillåtet att systematiskt använda
            ramar eller andra grafiska effekter i annonsens bild(er) som medför
            att annonsen framhävs på bekostnad av andra annonser i listningsvyn.
          </p>
          <p>
            Det är inte tillåtet att ta bilder och filmer från andra annonser
            utan den relevanta annonsörens medgivande. Dessa kan vara skyddade
            av upphovsrätt enligt Upphovsrättslagen (1960:729).
          </p>
          <p>
            Avseende bilder och annat innehåll i annonser, se vidare p. 11
            (Användargenererat innehåll och immateriella rättigheter) ovan i
            Användarvillkoren.
          </p>
        </div>
      </div>
    </TermsPage>
  );
}

export default Terms;
