import React, { useEffect, useRef, useMemo } from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from "react-leaflet-cluster";
import L from 'leaflet';
import "leaflet/dist/leaflet.css";
import IconV from "../../../assets/icons/vakansa-map-pin.png";
import IconVShadow from "../../../assets/icons/vakansa-map-pin-shadow.png";
import AdCard from "../AdCard/AdCard";
import "./mapSection.css";

function MapSection({ ad }) {
  const mapRef = useRef(null);
  
  const centerCoordinates = [60.31582836539714, 15.318138912667642];
  const mapZoom = 6;

  const customIcon = useMemo(() => {
    return L.icon({
      iconUrl: IconV,
      shadowUrl: IconVShadow,
      iconSize: [38, 37],
      iconAnchor: [28, 40],
      shadowSize: [38, 43],
      shadowAnchor: [17, 46],
    });
  }, []);

  const createClusterCustomIcon = useMemo(() => {
    return function (cluster) {
      return L.divIcon({
        html: `<span>${cluster.getChildCount()}</span>`,
        className: 'custom-marker-cluster',
        iconSize: L.point(33, 33, true),
      });
    };
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setView(centerCoordinates, mapZoom);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="map-section">
      <MapContainer className="map-container" center={centerCoordinates} zoom={mapZoom} scrollWheelZoom={true} ref={mapRef}>
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://carto.com/">CARTO</a> contributors'
        />
        <MarkerClusterGroup
          onClick={(e) => console.log('onClick', e)}
          iconCreateFunction={createClusterCustomIcon}
          maxClusterRadius={40}
          spiderfyOnMaxZoom={true}
          showCoverageOnHover={false}
        >
          {ad.map((ad, index) => (
            <Marker key={index} position={[ad.location.coordinates[1], ad.location.coordinates[0]]} icon={customIcon}>
              <Popup className="map-section-popup">
                <AdCard id={ad} />
              </Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>
      </MapContainer>
    </div>
  );
}

export default MapSection;
